$color_1: #24292e;
$color_2: #3599fd;
$color_3: white;
$color_4: #8e98a5;
$color_5: #2269f4;
$color_6: #f4f4f5;
$color_7: #4bad48;
$color_8: #ff4857;
$color_9: #ff9500;
$color_10: #61acfc;
$color_11: #f4f7fa;
$color_12: #3f485b;
$color_13: #293442;
$color_14: #696aff;
$color_15: #5a6c88;
$color_16: #919ba7;
$color_17: #40475c;
$color_18: #333;
$color_19: #2269f5;
$color_20: #6d7b91;
$color_21: #5e606b;
$color_22: #1e1e24;
$color_23: #fff;
$color_24: #969cad;
$color_25: #d3daed;
$color_26: #798094;
$color_27: #b0b7c7;
$color_28: #a1baff;
$color_29: #a0b0cc;
$color_30: #037afb;
$color_31: #c4d8ff;
$color_32: #435263;
$color_33: #13171f;
$color_34: #27313f;
$color_35: rgba(39,49,63,.55);
$color_36: #a7b9cf;
$color_37: #bfcfe3;
$color_38: rgba(64,71,92,.5);
$color_39: #7b8c9d;
$color_40: #4d546b;
$color_41: #364459;
$color_42: #787f96;
$color_43: rgba(255,255,255,.4);
$color_44: rgba(255,255,255,.6);
$color_45: #2482e0;
$color_46: #7884ad;
$color_47: #27323f;
$color_48: #6363ff;
$color_49: #404a5d;
$color_50: #fed548;
$color_51: #1f2433;
$color_52: #3a4055;
$color_53: #562cf5;
$color_54: #a1a5b5;
$color_55: #5d657e;
$color_56: #000;
$color_57: #3e485b;
$color_58: #293542;
$color_59: #939da9;
$color_60: #8e99a5;
$color_61: #909aa7;
$color_62: rgba(0,0,0,.55);
$font-family_1: Poppins,sans-serif;
$font-family_2: Montserrat,sans-serif;
$font-family_3: 'Material Icons';
$font-family_4: 'Material Icons Two Tone';
$background-color_1: #f9f9f9;
$background-color_2: #2269f4;
$background-color_3: #fceace;
$background-color_4: #4bad48;
$background-color_5: #ff4857;
$background-color_6: #ff9500;
$background-color_7: #61acfc;
$background-color_8: #f4f7fa;
$background-color_9: #3f485b;
$background-color_10: #fff;
$background-color_11: #f5f7fa;
$background-color_12: #1f2433;
$background-color_13: rgba(0,0,0,.1);
$background-color_14: transparent;
$background-color_15: rgba(229,234,240,.6);
$background-color_16: inherit;
$background-color_17: #27364a;
$background-color_18: #40475c;
$background-color_19: #e1e7ed;
$background-color_20: #bfc9d4;
$background-color_21: #dff0fe;
$background-color_22: #e6fcf0;
$background-color_23: #fce3e5;
$background-color_24: #fff3e3;
$background-color_25: #e0efff;
$background-color_26: #2269f5;
$background-color_27: #3e7dfa;
$background-color_28: #f2dbb3;
$background-color_29: #70d16d;
$background-color_30: #ff6673;
$background-color_31: #ffa930;
$background-color_32: #79b9fc;
$background-color_33: #dfe3e8;
$background-color_34: #4d556b;
$background-color_35: #badfff;
$background-color_36: #cfffe4;
$background-color_37: #ffc7cc;
$background-color_38: #ffe7c7;
$background-color_39: #c2dfff;
$background-color_40: #c2c2c2;
$background-color_41: #f5fbff;
$background-color_42: #e3e6ea;
$background-color_43: #75a3ff;
$background-color_44: #edf0f5;
$background-color_45: rgba(89,187,86,.9);
$background-color_46: rgba(255,72,87,.9);
$background-color_47: rgb(34,105,244,.06);
$background-color_48: #d4ebff;
$border-color_1: #e6e6e6;
$border-color_2: #037afb;
$border-color_3: #5780f7;
$border-color_4: #4bad48;
$border-color_5: #facc35;
$border-color_6: #eb463f;
$border-color_7: #e1e7ed;
$border-color_8: #2269f5;
$border-color_9: #ff4857;
$border-color_10: #ff9500;
$border-color_11: #61acfc;
$border-color_12: #40475c;
$border-color_13: #3e7dfa;
$border-color_14: #fceace;
$border-color_15: #f2dbb3;
$border-color_16: #70d16d;
$border-color_17: #ff6673;
$border-color_18: #ffa930;
$border-color_19: #79b9fc;
$border-color_20: #f4f7fa;
$border-color_21: #dfe3e8;
$border-color_22: #4d556b;
$border-color_23: transparent;
$border-color_24: #dff0fe;
$border-color_25: #e3e6eb;
$border-color_26: #e3e6ea;
$border-color_27: #e1e7ec;
$border-color_28: #ccd9e6;
$border-color_29: rgba(34,105,245,.6);
$border-color_30: #e2e6e9;
$border-top-color_1: #8fa5c1;
$border-top-color_2: #e3e6eb;
$border-right-color_1: #1f2433;
$border-right-color_2: #e3e6eb;
$border-bottom-color_1: #ccd6ed;
$border-bottom-color_2: #27364a;
$border-bottom-color_3: #e3e6eb;
$border-bottom-color_4: #ff4857;
$border-left-color_1: #e3e6eb;

body {
	font-size: 14px;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background-color: $background-color_1;
	font-weight: 400;
	color: $color_1;
	line-height: 1.6;
	-webkit-transition: background .2s ease-in-out;
	-moz-transition: background .2s ease-in-out;
	-o-transition: background .2s ease-in-out;
	transition: background .2s ease-in-out;
	font-family: $font-family_1;
}
html {
	font-size: 14px;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	overflow-x: hidden;
}
a {
	color: $color_2;
	font-family: $font-family_1;
	&:hover {
		color: $color_3;
		outline: 0 !important;
		text-decoration: none !important;
	}
	&:active {
		outline: 0 !important;
		text-decoration: none !important;
	}
	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}
}
p {
	line-height: 1.6;
}
small {
	font-size: 80%;
	font-weight: 400;
}
menu {
	padding-left: 0;
}
span {
	font-family: $font-family_1;
}
text {
	font-family: $font-family_1;
}
.bold {
	font-weight: 600;
}
b {
	font-weight: 600;
}
strong {
	font-weight: 600;
}
.overflow-hidden {
	overflow: hidden;
}
.overflow-x-hidden {
	overflow-x: hidden;
}
.overflow-y-hidden {
	overflow-y: hidden;
}
h1 {
	font-family: $font-family_1;
}
h2 {
	font-family: $font-family_1;
}
h3 {
	font-family: $font-family_1;
}
h4 {
	font-family: $font-family_1;
}
h5 {
	font-family: $font-family_1;
}
h6 {
	font-family: $font-family_1;
}
.divider {
	display: block;
	content: '';
	width: 100%;
	height: 1px;
	background: #e8e8e8;
	margin: 30px 0;
}
.no-s {
	margin: 0 !important;
	padding: 0 !important;
}
.no-m {
	margin: 0 !important;
}
.no-p {
	padding: 0 !important;
}
.m {
	margin: 15px;
}
.m-t-xxs {
	margin-top: 5px;
}
.m-r-xxs {
	margin-right: 5px;
}
.m-b-xxs {
	margin-bottom: 5px;
}
.m-l-xxs {
	margin-left: 5px;
}
.m-t-xs {
	margin-top: 10px;
}
.m-r-xs {
	margin-right: 10px;
}
.m-b-xs {
	margin-bottom: 10px;
}
.m-l-xs {
	margin-left: 10px;
}
.m-t-sm {
	margin-top: 15px;
}
.m-r-sm {
	margin-right: 15px;
}
.m-b-sm {
	margin-bottom: 15px;
}
.m-l-sm {
	margin-left: 15px;
}
.m-t-md {
	margin-top: 20px;
}
.m-r-md {
	margin-right: 20px;
}
.m-b-md {
	margin-bottom: 20px;
}
.m-l-md {
	margin-left: 20px;
}
.m-t-lg {
	margin-top: 25px;
}
.m-r-lg {
	margin-right: 25px;
}
.m-b-lg {
	margin-bottom: 25px;
}
.m-l-lg {
	margin-left: 25px;
}
.m-t-xxl {
	margin-top: 30px;
}
.m-r-xxl {
	margin-right: 30px;
}
.m-b-xxl {
	margin-bottom: 30px;
}
.m-l-xxl {
	margin-left: 30px;
}
.m-t-n-xxs {
	margin-top: -5px;
}
.m-r-n-xxs {
	margin-right: -5px;
}
.m-b-n-xxs {
	margin-bottom: -5px;
}
.m-l-n-xxs {
	margin-left: -5px;
}
.m-t-n-xs {
	margin-top: -10px;
}
.m-r-n-xs {
	margin-right: -10px;
}
.m-b-n-xs {
	margin-bottom: -10px;
}
.m-l-n-xs {
	margin-left: -10px;
}
.m-t-n-sm {
	margin-top: -15px;
}
.m-r-n-sm {
	margin-right: -15px;
}
.m-b-n-sm {
	margin-bottom: -15px;
}
.m-l-n-sm {
	margin-left: -15px;
}
.m-t-n-md {
	margin-top: -20px;
}
.m-r-n-md {
	margin-right: -20px;
}
.m-b-n-md {
	margin-bottom: -20px;
}
.m-l-n-md {
	margin-left: -20px;
}
.m-t-n-lg {
	margin-top: -25px;
}
.m-r-n-lg {
	margin-right: -25px;
}
.m-b-n-lg {
	margin-bottom: -25px;
}
.m-l-n-lg {
	margin-left: -25px;
}
.m-t-n-xxl {
	margin-top: -30px;
}
.m-r-n-xxl {
	margin-right: -30px;
}
.m-b-n-xxl {
	margin-bottom: -30px;
}
.m-l-n-xxl {
	margin-left: -30px;
}
.p {
	padding: 15px;
}
.p-h-xxs {
	padding-left: 5px;
	padding-right: 5px;
}
.p-h-xs {
	padding-left: 10px;
	padding-right: 10px;
}
.p-h-sm {
	padding-left: 15px;
	padding-right: 15px;
}
.no-p-h {
	padding-left: 0;
	padding-right: 0;
}
.no-p-v {
	padding-top: 0;
	padding-bottom: 0;
}
.p-h-md {
	padding-left: 20px;
	padding-right: 20px;
}
.p-h-lg {
	padding-left: 25px;
	padding-right: 25px;
}
.p-h-xxl {
	padding-left: 30px;
	padding-right: 30px;
}
.p-v-xxs {
	padding-top: 5px;
	padding-bottom: 5px;
}
.p-v-xs {
	padding-top: 10px;
	padding-bottom: 10px;
}
.p-v-sm {
	padding-top: 15px;
	padding-bottom: 15px;
}
.p-v-md {
	padding-top: 20px;
	padding-bottom: 20px;
}
.p-v-lg {
	padding-top: 25px;
	padding-bottom: 25px;
}
.p-v-xxl {
	padding-top: 30px;
	padding-bottom: 30px;
}
.full-height {
	height: 100% !important;
}
.full-width {
	width: 100% !important;
}
.w-xxs {
	width: 50px;
}
.w-xs {
	width: 90px;
}
.w-sm {
	width: 140px;
}
.w {
	width: 200px;
}
.w-md {
	width: 240px;
}
.w-lg {
	width: 280px;
}
.w-xl {
	width: 330px;
}
.w-xxl {
	width: 370px;
}
.w-auto {
	width: auto;
}
.h-auto {
	height: auto;
}
.no-b {
	border: 0 !important;
}
.b {
	border: 1px solid #e6e6e6;
}
.b-t {
	border-top: 1px solid #e6e6e6;
}
.b-r {
	border-right: 1px solid #e6e6e6;
}
.b-b {
	border-bottom: 1px solid #e6e6e6;
}
.b-l {
	border-left: 1px solid #e6e6e6;
}
.b-default {
	border-color: $border-color_1;
}
.b-primary {
	border-color: $border-color_2;
}
.b-info {
	border-color: $border-color_3;
}
.b-success {
	border-color: $border-color_4;
}
.b-warning {
	border-color: $border-color_5;
}
.b-danger {
	border-color: $border-color_6;
}
.b-2 {
	border-width: 2px;
}
.b-3 {
	border-width: 3px;
}
.b-4 {
	border-width: 4px;
}
.b-n-t {
	border-top: 0;
}
.b-n-r {
	border-right: 0;
}
.b-n-b {
	border-bottom: 0;
}
.b-n-l {
	border-left: 0;
}
.rounded {
	border-radius: 5px !important;
}
.no-r {
	border-radius: 0 !important;
}
.r {
	border-radius: 2px;
}
.r-t-l {
	border-top-left-radius: 2px;
}
.r-t-r {
	border-top-right-radius: 2px;
}
.r-b-l {
	border-bottom-left-radius: 2px;
}
.r-b-r {
	border-bottom-right-radius: 2px;
}
.no-r-t-l {
	border-top-left-radius: 0 !important;
}
.no-r-t-r {
	border-top-right-radius: 0 !important;
}
.no-r-b-l {
	border-bottom-left-radius: 0 !important;
}
.no-r-b-r {
	border-bottom-right-radius: 0 !important;
}
.bg-primary {
	background-color: $background-color_2 !important;
}
.bg-secondary {
	background-color: $background-color_3 !important;
}
.bg-success {
	background-color: $background-color_4 !important;
}
.bg-danger {
	background-color: $background-color_5 !important;
}
.bg-warning {
	background-color: $background-color_6 !important;
}
.bg-info {
	background-color: $background-color_7 !important;
}
.bg-light {
	background-color: $background-color_8 !important;
}
.bg-dark {
	background-color: $background-color_9 !important;
}
.text-muted {
	color: $color_4 !important;
}
.text-primary {
	color: $color_5 !important;
}
.text-secondary {
	color: $color_6 !important;
}
.text-success {
	color: $color_7 !important;
}
.text-danger {
	color: $color_8 !important;
}
.text-warning {
	color: $color_9 !important;
}
.text-info {
	color: $color_10 !important;
}
.text-light {
	color: $color_11 !important;
}
.text-dark {
	color: $color_12 !important;
}
.blockquote-footer {
	margin-top: -12px;
	margin-bottom: 12px;
	font-size: 15px;
	color: $color_4;
}
.app {
	height: 100%;
}
.app-container {
	margin-left: 280px;
	width: calc(100vw - 280px);
	padding: 30px;
	position: relative;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.app-header {
	position: fixed;
	width: calc(100% - 340px);
	right: 30px;
	z-index: 1001;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 131px;
		background: linear-gradient(180deg,rgba(231,236,248,.8) 0,#e7ecf8 40%,#e7ecf8 78%,rgba(231,236,248,0) 100%);
		position: fixed;
		top: 0;
		left: 0;
	}
	.navbar {
		height: 70px;
		padding: 0 15px;
		border-radius: 10px;
		background-color: $background-color_10;
		box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
		-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
		-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
		.navbar-nav {
			>li {
				position: relative;
				>a {
					padding-left: 10px;
					padding-right: 10px;
					margin-right: 5px;
					line-height: 30px;
					position: relative;
					>i {
						vertical-align: middle;
					}
				}
				&:last-child {
					>a {
						margin-right: 0;
					}
				}
				>a.active {
					color: $color_13;
					&::after {
						content: '';
						width: 5px;
						height: 5px;
						display: block;
						position: absolute;
						left: 50%;
						bottom: 0;
						transform: translateX(-2.5px);
						border-radius: 2.5px;
						background: #2269f5;
					}
				}
			}
		}
	}
	.nav-notifications-toggle {
		padding: 6.5px 15px;
		line-height: 22px !important;
		border-radius: 17.5px;
		min-width: 35px;
		text-align: center;
		font-weight: 700;
		margin: 4.5px 10px !important;
		background: #e3e3ff;
		color: $color_14 !important;
	}
	.notifications-dropdown {
		width: 350px;
		max-height: 400px;
		.notifications-dropdown-list {
			overflow-y: auto;
			max-height: 350px;
			margin: 0 -10px;
		}
		a {
			text-decoration: none;
		}
		.notifications-dropdown-item-image {
			float: left;
			margin-right: 10px;
			margin-top: 3px;
			border-radius: 50%;
			span {
				width: 40px;
				height: 40px;
				display: block;
				line-height: 40px;
				text-align: center;
				border-radius: 100%;
				display: inline-block;
				i {
					line-height: 40px;
					font-size: 22px;
				}
			}
			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}
		}
		.notifications-dropdown-item-text {
			flex: 1;
			padding: 0 5px;
			display: inline-block;
			color: $color_15;
			font-weight: 400;
			overflow: hidden;
			.bold-notifications-text {
				font-weight: 500;
			}
			p {
				margin: 0;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			small {
				opacity: .7;
				color: $color_16;
			}
		}
	}
	.notifications-dropdown-item {
		padding: 10px 20px;
		display: flex;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		&:hover {
			background-color: $background-color_8;
		}
	}
	.language-dropdown-toggle {
		margin: 6px 5px !important;
		padding: 0;
		img {
			height: 30px;
			width: 30px;
		}
	}
	.language-dropdown {
		min-width: 150px;
		a {
			img {
				width: 25px;
				height: 25px;
				margin-right: 15px;
			}
		}
	}
	.header-toolbar {
		height: 61px;
		position: relative;
		border-top: 1px solid #f4f7fa;
		background: #f4f7fa;
		padding: 0 30px;
		.header-toolbar-menu {
			float: left;
			margin-bottom: 0;
			padding-left: 0;
			>li {
				display: inline-block;
				height: 60px;
				>a {
					font-size: 13px;
					font-weight: 500;
					text-decoration: none;
					color: $color_29;
					margin: 10px 5px;
					padding: 9px 10px;
					display: block;
					border-radius: 10px;
					-webkit-transition: all .2s ease-in-out;
					-moz-transition: all .2s ease-in-out;
					-o-transition: all .2s ease-in-out;
					transition: all .2s ease-in-out;
					&:hover {
						color: $color_30;
						background-color: $background-color_15;
					}
				}
			}
		}
		.header-toolbar-actions {
			float: right;
			padding: 13.5px 0;
		}
	}
	.toggle-search {
		margin-right: 0 !important;
	}
}
.full-width-header {
	.app-header {
		&::before {
			height: 100px;
		}
		position: fixed;
		width: calc(100% - 280px);
		right: 0;
		z-index: 1001;
		top: 0;
		border-radius: 0;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		.navbar {
			height: 70px;
			padding: 0 45px;
			border-radius: 0;
			background-color: $background-color_10;
		}
	}
	.app-menu {
		height: calc(100% - 70px) !important;
	}
	.app-content {
		margin-top: 70px;
	}
	.search {
		width: calc(100% - 280px);
		left: 280px;
		right: 0;
		height: 70px;
		top: 0 !important;
		border-radius: 0;
		padding: 13.5px 45px;
	}
}
.app-sidebar {
	width: 280px;
	background-color: $background-color_10;
	height: 100vh;
	position: fixed;
	padding: 0;
	z-index: 1003;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	.logo {
		overflow: hidden;
		padding: 45px 28px;
		position: relative;
		background-color: $background-color_11;
		-webkit-transition: opacity .2s ease-in-out;
		-moz-transition: opacity .2s ease-in-out;
		-o-transition: opacity .2s ease-in-out;
		transition: opacity .2s ease-in-out;
		.logo-icon {
			width: 40px;
			height: 40px;
			font-size: 18px;
			zbackground-position: center left;
			background-size: 40px;
			display: block;
			text-decoration: none;
			font-weight: 700;
			border-radius: 30px;
			float: left;
			padding-left: 45px;
			line-height: 40px;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			&:hover {
				padding-left: 55px;
				.logo-text {
					visibility: visible;
					opacity: 1;
				}
			}
			&:hover+.sidebar-user-switcher {
				margin-right: -10px;
				visibility: hidden;
				opacity: 0;
			}
		}
		.sidebar-user-switcher {
			float: right;
			position: relative;
			opacity: 1;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			a {
				display: block;
				overflow: hidden;
			}
			img {
				float: right;
				width: 40px;
				border-radius: 5px;
				margin-left: 10px;
			}
			.user-info-text {
				float: right;
				text-align: right;
				color: $color_18;
				line-height: 20px;
				.user-state-info {
					opacity: .6;
					font-size: 13px;
				}
			}
		}
		.sidebar-user-switcher.user-activity-online {
			.activity-indicator {
				display: block;
				position: absolute;
				width: 14px;
				height: 14px;
				background: #4bad48;
				border-radius: 7px;
				border: 2px solid #f5f7fa;
				bottom: -4px;
				right: -4px;
			}
		}
	}
	.logo-icon {
		.logo-text {
			color: $color_17;
			visibility: hidden;
			opacity: 0;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
		}
	}
	.logo.logo-sm {
		padding: 15px 33px;
		background: #fff;
		a {
			text-decoration: none;
			font-size: 20px;
			color: $color_13;
			font-weight: 700;
			line-height: 40px;
		}
	}
}
.app-menu {
	height: calc(100% - 131px) !important;
	padding-top: 15px;
	padding-bottom: 15px;
	position: relative;
	width: 100%;
	ul {
		margin: 0;
		padding: 0;
		li {
			list-style-type: none;
		}
	}
	>ul {
		>li {
			ul {
				margin: 10px 35px 10px 30px;
				padding: 20px 0;
				border-radius: 10px;
				background: #f5f7fa;
				li {
					a {
						padding: 5px 33px;
						text-decoration: none;
						color: $color_21;
						font-size: 13px;
						display: block;
						-webkit-transition: all .2s ease-in-out;
						-moz-transition: all .2s ease-in-out;
						-o-transition: all .2s ease-in-out;
						transition: all .2s ease-in-out;
						&:hover {
							color: $color_22;
							font-weight: 500;
						}
					}
					a.active {
						color: $color_22;
						font-weight: 500;
					}
				}
			}
			>a {
				text-decoration: none;
				color: $color_17;
				font-size: 14px;
				margin: 3px 15px;
				padding: 10px 15px;
				display: block;
				border-radius: 3px;
				line-height: 20px;
				-webkit-transition: all .2s ease-in-out;
				-moz-transition: all .2s ease-in-out;
				-o-transition: all .2s ease-in-out;
				transition: all .2s ease-in-out;
				&:hover {
					color: $color_19;
					padding-left: 20px;
					>i {
						&:not(.has-sub-menu).material-icons-two-tone {
							filter: invert(34%) sepia(23%) saturate(3309%) hue-rotate(195deg) brightness(109%) contrast(115%);
						}
					}
				}
				>i {
					&:not(.has-sub-menu) {
						margin-right: 13px;
						vertical-align: top;
						line-height: 20px;
						filter: invert(41%) sepia(17%) saturate(674%) hue-rotate(182deg) brightness(95%) contrast(89%);
						-webkit-transition: all .2s ease-in-out;
						-moz-transition: all .2s ease-in-out;
						-o-transition: all .2s ease-in-out;
						transition: all .2s ease-in-out;
					}
				}
			}
		}
		>li.active-page {
			>a {
				color: $color_19;
				>i {
					&:not(.has-sub-menu).material-icons-two-tone {
						filter: invert(34%) sepia(23%) saturate(3309%) hue-rotate(195deg) brightness(109%) contrast(115%);
					}
				}
			}
		}
		>li.open {
			>a {
				color: $color_19;
				>i {
					&:not(.has-sub-menu).material-icons-two-tone {
						filter: invert(34%) sepia(23%) saturate(3309%) hue-rotate(195deg) brightness(109%) contrast(115%);
					}
				}
			}
		}
		ul {
			li.open {
				>a {
					color: $color_22;
					font-weight: 500;
				}
			}
		}
	}
}
.accordion-menu {
	li {
		>a {
			>i.has-sub-menu {
				float: right;
				margin-right: 0;
				margin-top: -2px;
				color: $color_20;
				font-size: 19px;
				line-height: 24px;
				-webkit-transition: all .2s ease-in-out;
				-moz-transition: all .2s ease-in-out;
				-o-transition: all .2s ease-in-out;
				transition: all .2s ease-in-out;
			}
		}
	}
	li.open {
		>a {
			>i.has-sub-menu {
				transform: rotate(90deg);
			}
		}
	}
	li.sidebar-title {
		padding: 20px 15px 10px 30px;
		font-weight: 700;
		text-transform: uppercase;
		color: $color_17;
		font-size: 12px;
		opacity: .35;
		font-family: $font-family_2;
	}
	>li {
		>ul {
			ul {
				margin: 0;
				padding: 5px 0 5px 20px;
			}
		}
	}
}
.pace {
	.pace-progress {
		z-index: 999999;
	}
}
.app-content {
	margin-top: 100px;
	.container-fluid {
		padding: 0;
	}
	.card {
		margin-bottom: 20px;
	}
	.content-menu {
		width: 250px;
		padding: 20px 0;
		position: fixed;
		height: calc(100vh - 160px);
		ul {
			margin-bottom: 0;
		}
		li {
			a {
				padding: 5px 30px;
				display: block;
				color: $color_32;
				text-decoration: none;
				margin: 5px 0;
				position: relative;
				-webkit-transition: all .2s ease-in-out;
				-moz-transition: all .2s ease-in-out;
				-o-transition: all .2s ease-in-out;
				transition: all .2s ease-in-out;
				&:hover {
					font-weight: 500;
					color: $color_33;
				}
				i {
					vertical-align: middle;
					margin-right: 10px;
				}
			}
			a.active {
				font-weight: 500;
				color: $color_33;
				&::after {
					height: 100%;
					width: 3px;
					content: '';
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					background: #037afb;
				}
			}
		}
		li.divider {
			background: #ccd8ed;
		}
	}
	.content-menu-toggle {
		display: none;
	}
	.content-menu.content-menu-left {
		float: left;
		border-right: 1px solid #ccd7ed;
		margin-right: -10.5px;
		&::after {
			content: '';
			width: 1px;
			height: 100%;
			position: fixed;
			right: 0;
			top: 0;
			bottom: 0;
		}
	}
	.content-menu.content-menu-left+.content-wrapper {
		float: right;
		width: calc(100% - 280px);
		.page-description {
			margin-left: -30px;
		}
		.container {
			.page-description {
				margin-left: -40.5px;
				padding-left: 40.5px;
			}
		}
	}
	.content-menu.content-menu-right {
		right: 30px;
		border-left: 1px solid #ccd7ed;
		margin-left: -10.5px;
		&::after {
			content: '';
			width: 1px;
			height: 100%;
			position: fixed;
			left: 0;
			top: 0;
			bottom: 0;
		}
		li {
			a.active {
				&::after {
					left: 0;
				}
			}
		}
	}
	.content-menu.content-menu-right+.content-wrapper {
		float: left;
		width: calc(100% - 280px);
		.page-description {
			margin-right: -30px;
		}
		.container {
			.page-description {
				margin-right: -40.5px;
				padding-right: 40.5px;
			}
		}
	}
	.page-description {
		overflow: hidden;
		padding: 30px;
		font-size: 15px;
		margin-bottom: 30px;
		border-bottom: 1px solid #ccd6ed;
		span {
			i {
				font-size: 14px;
			}
		}
		h1 {
			font-weight: 700;
			color: $color_34;
			margin-bottom: 0;
		}
		h1+span {
			margin-top: 25px;
			display: block;
		}
	}
	.page-description-tabbed {
		padding-bottom: 0;
		border-bottom: none;
		.nav-tabs {
			margin: 20px -30px;
			padding: 0 30px;
			border-bottom-color: $border-bottom-color_1;
		}
	}
}
.app.sidebar-hidden {
	.app-container {
		margin-left: 0;
		width: 100%;
	}
	.app-header {
		width: calc(100% - 360px);
	}
	.app-sidebar {
		transform: translateX(-280px);
		.logo {
			opacity: 0;
		}
		.logo.hidden-sidebar-logo {
			position: fixed;
			width: 280px;
			transform: translateX(310px);
			height: 70px;
			top: 30px;
			padding-top: 15px;
			padding-bottom: 15px;
			border-radius: 10px;
			background-color: $background-color_10;
			opacity: 1;
			box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
			-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
			-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
		}
	}
	.search {
		width: calc(100% - 360px);
		left: 330px;
	}
}
.app.menu-off-canvas {
	.app-container {
		margin-left: 0;
		width: 100%;
	}
	.app-header {
		left: 310px;
	}
	.app-sidebar {
		transform: translateX(-280px);
		.app-menu {
			height: 100% !important;
		}
		.logo.canvas-sidebar-hidden-logo {
			transform: translateX(0);
			opacity: 0;
			visibility: hidden;
		}
		.logo {
			position: fixed;
			width: 260px;
			transform: translateX(310px);
			height: 70px;
			top: 30px;
			padding-top: 15px;
			padding-bottom: 15px;
			border-radius: 10px;
			background-color: $background-color_10;
			opacity: 1;
			box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
			-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
			-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
		}
	}
}
.app.menu-off-canvas.menu-off-canvas-show {
	.app-sidebar {
		transform: translateX(0);
	}
}
.app.menu-dark {
	.app-sidebar {
		background: #13171f;
		.logo {
			background-color: $background-color_12;
			.sidebar-user-switcher {
				.user-info-text {
					color: $color_27;
				}
			}
		}
		.logo-icon {
			.logo-text {
				color: $color_27;
			}
		}
	}
	&:not(.sidebar-hidden) {
		.app-sidebar {
			.logo.logo-sm {
				a {
					color: $color_23;
				}
			}
		}
	}
	.app-menu {
		>ul {
			>li {
				>a {
					color: $color_24;
					>i {
						&:not(.has-sub-menu).material-icons-two-tone {
							filter: invert(50%) sepia(8%) saturate(768%) hue-rotate(187deg) brightness(91%) contrast(81%);
						}
					}
					&:hover {
						color: $color_25;
						>i {
							&:not(.has-sub-menu).material-icons-two-tone {
								filter: invert(86%) sepia(24%) saturate(158%) hue-rotate(188deg) brightness(101%) contrast(86%);
							}
						}
					}
				}
				ul {
					background: #1f2433;
					li {
						a {
							color: $color_26;
							&:hover {
								color: $color_23;
							}
						}
						a.active {
							color: $color_23;
						}
					}
				}
			}
			>li.active-page {
				>a {
					>i {
						&:not(.has-sub-menu).material-icons-two-tone {
							filter: invert(86%) sepia(24%) saturate(158%) hue-rotate(188deg) brightness(101%) contrast(86%);
						}
					}
					color: $color_23;
				}
			}
			>li.open {
				>a {
					>i {
						&:not(.has-sub-menu).material-icons-two-tone {
							filter: invert(86%) sepia(24%) saturate(158%) hue-rotate(188deg) brightness(101%) contrast(86%);
						}
					}
				}
			}
			li.open {
				>a {
					color: $color_23;
				}
			}
		}
	}
	.accordion-menu {
		li.sidebar-title {
			color: $color_27;
			opacity: .4;
		}
	}
}
.app.menu-dark.sidebar-hidden {
	.app-sidebar {
		.logo {
			.sidebar-user-switcher {
				.user-info-text {
					color: $color_18;
				}
			}
		}
		.logo-icon {
			.logo-text {
				color: $color_17;
			}
		}
	}
}
.app.sidebar-colored {
	.app-sidebar {
		background: #004be0;
		.logo {
			background-color: $background-color_13;
			.sidebar-user-switcher {
				.user-info-text {
					color: $color_23;
				}
			}
		}
		.logo-sm {
			a {
				color: $color_23;
			}
		}
		.logo-icon {
			.logo-text {
				color: $color_23;
			}
		}
	}
	.app-menu {
		>ul {
			>li {
				>a {
					color: $color_28;
					font-weight: 500;
					>i {
						&:not(.has-sub-menu).material-icons-two-tone {
							filter: invert(83%) sepia(24%) saturate(6045%) hue-rotate(190deg) brightness(105%) contrast(101%);
						}
					}
					>i.has-sub-menu {
						color: $color_28;
					}
					&:hover {
						color: $color_23;
						>i {
							&:not(.has-sub-menu).material-icons-two-tone {
								filter: invert(99%) sepia(5%) saturate(548%) hue-rotate(211deg) brightness(117%) contrast(100%);
							}
						}
					}
				}
				ul {
					background: 0 0;
					li {
						a {
							color: $color_28;
							&:hover {
								color: $color_23;
							}
						}
						a.active {
							color: $color_23;
						}
					}
				}
				>ul {
					background-color: $background-color_13;
				}
			}
			>li.active-page {
				>a {
					>i {
						&:not(.has-sub-menu).material-icons-two-tone {
							filter: invert(99%) sepia(5%) saturate(548%) hue-rotate(211deg) brightness(117%) contrast(100%);
						}
					}
					color: $color_23;
				}
			}
			>li.open {
				>a {
					>i {
						&:not(.has-sub-menu).material-icons-two-tone {
							filter: invert(99%) sepia(5%) saturate(548%) hue-rotate(211deg) brightness(117%) contrast(100%);
						}
					}
				}
			}
			li.open {
				>a {
					color: $color_23;
				}
			}
		}
	}
	.accordion-menu {
		li.sidebar-title {
			color: $color_28;
			opacity: .6;
		}
	}
}
.app.sidebar-colored.sidebar-hidden {
	.app-sidebar {
		.logo {
			.sidebar-user-switcher {
				.user-info-text {
					color: $color_18;
				}
			}
		}
		.logo-icon {
			.logo-text {
				color: $color_17;
			}
		}
	}
}
.app.sidebar-hidden.full-width-header {
	.app-header {
		left: auto;
		width: calc(100% - 240px);
	}
	.app-sidebar {
		.logo.hidden-sidebar-logo {
			top: 0;
			left: 0;
			transform: translateX(280px);
			width: 240px;
			padding-left: 60px;
			border-radius: 0;
			background: #fff;
			color: $color_13;
			box-shadow: none;
		}
	}
}
.header-transaprent {
	.app-header {
		position: relative;
		width: 100%;
		right: 0;
		-webkit-transition: none;
		-moz-transition: none;
		-o-transition: none;
		transition: none;
		.navbar {
			background-color: $background-color_14;
			box-shadow: none;
		}
		&::before {
			content: none;
		}
	}
	.app-content {
		margin-top: 30px;
	}
}
.app.sidebar-hidden.header-transaprent {
	.app-header {
		left: 0;
		padding-left: 280px;
	}
	.app-sidebar {
		.logo.hidden-sidebar-logo {
			position: relative;
		}
		transform: none;
		margin-left: -280px;
		position: relative;
	}
}
.header-large {
	.app-header {
		position: relative;
		width: 100%;
		right: 0;
		background: #fff;
		height: 131px;
		margin: -30px;
		width: calc(100% + 60px);
		-webkit-transition: none;
		-moz-transition: none;
		-o-transition: none;
		transition: none;
		box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
		-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
		-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
		&::before {
			display: none;
		}
		.navbar {
			box-shadow: none;
			background-color: $background-color_14;
			padding: 0 30px;
		}
	}
	.app-content {
		margin-top: 50px;
	}
	.search {
		width: calc(100% - 283px);
		left: 283px;
		right: 0;
		height: 70px;
		border-radius: 0;
		padding: 13.5px 45px;
	}
}
.header-large.sidebar-hidden {
	.app-sidebar {
		.logo.hidden-sidebar-logo {
			top: 0;
			left: 0;
			transform: translateX(280px);
			border-radius: 0;
			width: 310px;
			box-shadow: none;
			border-right: 1px solid #e3e6ea;
			padding: 15px 40.5px;
		}
	}
	.app-header {
		width: calc(100% + 60px);
		margin-right: 0;
		left: 0;
		.navbar {
			margin-left: 310px;
		}
	}
}
.header-colorful {
	.app-header {
		.navbar {
			background-color: $background-color_2;
			.navbar-nav {
				>li {
					>a {
						color: $color_31;
						&:hover {
							color: $color_23;
						}
					}
					>a.active {
						color: $color_23;
						&::after {
							background-color: $background-color_10;
						}
					}
				}
			}
		}
	}
}
.section-description {
	overflow: hidden;
	padding: 20px 30px;
	font-size: 15px;
	margin-bottom: 30px;
	border-bottom: 1px solid #ccd6ed;
	h1 {
		font-weight: 700;
		color: $color_34;
		margin-bottom: 0;
		font-size: 24px;
		.badge {
			vertical-align: middle;
			margin-left: 10px;
			font-size: 12px;
		}
	}
	span {
		i {
			font-size: 14px;
		}
		font-size: 14px;
	}
	h1+span {
		margin-top: 15px;
		display: block;
	}
}
.section-description-inline {
	h1 {
		float: left;
		margin-right: 20px;
	}
	h1+span {
		line-height: 28px;
		float: left;
		vertical-align: middle;
		margin-top: 0;
		color: $color_35;
	}
}
.example-container {
	border: 5px solid #f4f7fa;
	border-radius: 10px;
	.example-content {
		padding: 20px;
		border-radius: 10px;
	}
	.example-code {
		background: #f4f7fa;
		position: relative;
		padding: 10px 20px 20px;
		.tab-content {
			margin-top: 20px;
		}
		pre {
			margin: 0;
			max-height: 300px;
		}
		>pre {
			margin-top: 10px;
		}
		.hljs {
			padding: 0;
			background-color: $background-color_16;
		}
	}
	.dropdown-menu-example-open {
		display: block;
		visibility: visible;
		opacity: 1;
		position: relative;
		margin-top: 0;
	}
}
.placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.placeholder-img-lg {
	font-size: calc(1.475rem + 2.7vw);
}
.dropdown-menu {
	display: block;
	visibility: hidden;
	margin-top: 40px;
	opacity: 0;
	border: none;
	border-radius: 10px;
	padding: 10px;
	min-width: 200px;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	&:after {
		position: absolute;
		top: -7px;
		left: 10px;
		right: auto;
		display: inline-block !important;
		border-right: 7px solid transparent;
		border-bottom: 7px solid #fff;
		border-left: 7px solid transparent;
		content: '';
	}
	li {
		a {
			color: $color_17;
			padding: 5px 10px;
			margin: 5px 0;
			display: block;
			font-size: 13px;
			font-weight: 400;
			position: relative;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			&:hover {
				background: 0 0;
				font-weight: 500;
			}
		}
		a.active {
			color: $color_34;
			font-weight: 500;
			background-color: $background-color_14;
		}
	}
	.dropdown-header {
		padding: 10px;
		text-transform: uppercase;
		font-weight: 600;
		color: $color_38;
	}
}
.dropdown-menu.dropdown-menu.dropdown-menu-dark {
	background-color: $background-color_17;
	li {
		a {
			color: $color_36;
			&:hover {
				color: $color_37;
				background-color: $background-color_14;
			}
		}
		a.active {
			color: $color_23;
			font-weight: 500;
			background-color: $background-color_14;
		}
	}
}
.dropdown-menu.dropdown-menu-dark {
	&:after {
		border-bottom-color: $border-bottom-color_2;
	}
}
.dropdown-toggle {
	&::after {
		vertical-align: middle;
	}
}
.dropdown-divider {
	border-top-color: $border-top-color_1;
	margin: 10px;
}
.dropdown-menu-dark {
	.dropdown-divider {
		border-top-color: $border-top-color_1;
		margin: 10px;
	}
}
.btn-group.dropend {
	>.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.btn-group.dropstart {
	>.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.btn-group.dropup {
	>.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.dropdown-menu[data-popper-placement=left-start] {
	&::after {
		display: none !important;
	}
	margin-right: 10px !important;
	top: auto !important;
}
.dropdown-menu[data-popper-placement=right-start] {
	&::after {
		display: none !important;
	}
	margin-left: 10px !important;
	top: auto !important;
}
.dropdown-menu[data-popper-placement=top-start] {
	&::after {
		display: none !important;
	}
	margin-bottom: 10px !important;
	top: auto !important;
}
.dropdown-toggle.dropend {
	>.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.dropdown-toggle.dropstart {
	>.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.dropdown-toggle.dropup {
	>.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.dropdown.dropend {
	>.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.dropdown.dropstart {
	>.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.dropdown.dropup {
	>.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.dropup {
	.dropdown-menu {
		&::after {
			display: none !important;
		}
	}
}
.dropdown-menu[data-popper-placement=bottom-end] {
	&::after {
		right: 23px;
		left: auto;
	}
}
.navbar {
	.dropdown-menu {
		&:after {
			left: 23px;
			right: auto;
		}
		top: calc(100% + 30px) !important;
		margin-top: 20px !important;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		&:not(.dropdown-menu-end) {
			left: -10px;
		}
	}
	.dropdown-menu-end {
		&:after {
			right: 23px;
			left: auto;
		}
		right: 0;
		left: auto;
	}
	.dropdown-menu.show {
		margin-top: 0 !important;
	}
	.dropdown-toggle {
		&::after {
			display: none;
		}
	}
}
.dropdown-menu.show {
	opacity: 1;
	transform: none;
	visibility: visible;
	top: 20px !important;
}
.dropdown-menu.dropdown-lg {
	width: 400px;
}
.dropdown-menu.large-items-menu {
	padding-left: 0;
	padding-right: 0;
	li {
		a {
			white-space: normal;
			padding: 15px 30px;
			margin: 0;
			&:hover {
				font-weight: 400;
				background: rgba(238,241,245,.7);
				.dropdown-item-title {
					.hidden-helper-text {
						opacity: .7;
						margin-right: 0;
					}
				}
			}
			.dropdown-item-title {
				.badge {
					font-size: 10px !important;
					vertical-align: middle;
					margin-left: 3px;
				}
				.hidden-helper-text {
					float: right;
					font-size: 13px;
					opacity: 0;
					margin: 0 10px 0 0;
					-webkit-transition: all .2s ease-in-out;
					-moz-transition: all .2s ease-in-out;
					-o-transition: all .2s ease-in-out;
					transition: all .2s ease-in-out;
					i {
						font-size: 14px;
						vertical-align: middle;
						margin-right: 0;
					}
				}
			}
			.dropdown-item-description {
				opacity: .6;
			}
		}
		&:not(:last-child) {
			a {
				&::after {
					content: "";
					width: calc(100% - 60px);
					position: absolute;
					left: 30px;
					bottom: 0;
					height: 1px;
					background: #e3e6eb;
					opacity: .6;
					display: block;
				}
			}
		}
	}
	li.dropdown-btn-item {
		padding: 15px 30px;
	}
	.dropdown-header {
		padding: 10px 30px;
	}
}
.table-dark {
	th {
		background-color: $background-color_18;
		color: $color_23 !important;
	}
}
.table-light {
	th {
		background-color: $background-color_8;
	}
}
.table {
	th {
		font-weight: 500;
		color: $color_39;
		padding: 15px 20px !important;
	}
	* {
		border-color: $border-color_7 !important;
	}
	td {
		padding: 15px 20px !important;
	}
	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
				th {
					border-bottom: none;
				}
			}
		}
	}
	tfoot {
		tr {
			td {
				border-bottom: none;
				border-top-width: 1px;
			}
			th {
				border-bottom: none;
				border-top-width: 1px;
			}
		}
	}
}
.table-hover {
	>tbody {
		>tr {
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			&:hover {
				--bs-table-hover-bg: transparent;
				background: #f4f7fa;
			}
		}
	}
}
.table-striped {
	>tbody {
		>tr {
			&:nth-of-type(odd) {
				--bs-table-striped-bg: #F4F7FA;
			}
		}
	}
}
.dataTables_scroll {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
	.dataTables_scrollBody {
		thead {
			display: none;
		}
	}
}
table.dataTable {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
	td {
		padding: 15px 20px !important;
		border-bottom: 1px solid #e1e7ed !important;
	}
	th {
		padding: 15px 20px !important;
		border-bottom: 1px solid #e1e7ed !important;
	}
	tfoot {
		th {
			border-bottom: 0 !important;
			font-weight: 500;
			color: $color_39;
		}
	}
	thead {
		th {
			font-weight: 500;
			color: $color_39;
		}
		.sorting {
			&:before {
				content: '\e316';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 4px);
			}
			&:after {
				content: '\e313';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 14px);
			}
		}
		.sorting_asc {
			&:before {
				content: '\e316';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 4px);
			}
			&:after {
				content: '\e313';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 14px);
			}
		}
		.sorting_asc_disabled {
			&:before {
				content: '\e316';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 4px);
			}
			&:after {
				content: '\e313';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 14px);
			}
		}
		.sorting_desc {
			&:before {
				content: '\e316';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 4px);
			}
			&:after {
				content: '\e313';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 14px);
			}
		}
		.sorting_desc_disabled {
			&:before {
				content: '\e316';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 4px);
			}
			&:after {
				content: '\e313';
				font-family: $font-family_3;
				font-size: 14px;
				right: 10px;
				bottom: calc(50% - 14px);
			}
		}
	}
}
div.dataTables_wrapper {
	div.dataTables_length {
		select {
			margin: 0 10px;
		}
	}
	div.dataTables_filter {
		input {
			margin-left: 10px;
		}
	}
}
tr.group {
	background-color: $background-color_19 !important;
}
.alert {
	border: none;
	border-radius: 10px;
	padding: 20px 30px;
}
.alert-rounded {
	border-radius: 100px;
}
.alert.alert-primary {
	background: #2269f5;
	color: $color_23;
}
.alert.alert-secondary {
	background: #fceace;
	color: $color_40;
}
.alert.alert-success {
	background: #4bad48;
	color: $color_23;
}
.alert.alert-danger {
	background: #ff4857;
	color: $color_23;
}
.alert.alert-warning {
	background: #ff9500;
	color: $color_23;
}
.alert.alert-info {
	background: #61acfc;
	color: $color_23;
}
.alert.alert-light {
	background: #f4f7fa;
	color: $color_40;
}
.alert.alert-dark {
	background: #40475c;
	color: $color_23;
}
.alert.alert-style-light.alert-primary {
	background: #dff0fe;
	color: $color_19;
}
.alert.alert-style-light.alert-success {
	background: #e6fcf0;
	color: $color_7;
}
.alert.alert-style-light.alert-danger {
	background: #fce3e5;
	color: $color_8;
}
.alert.alert-style-light.alert-warning {
	background: #fff3e3;
	color: $color_9;
}
.alert.alert-style-light.alert-info {
	background: #e0efff;
	color: $color_10;
}
.alert.alert-style-light.alert-dark {
	background: #d4d4d4;
	color: $color_17;
}
.alert-custom {
	position: relative;
	padding: 10px 15px;
	border-radius: 10px;
	background-color: $background-color_10;
	overflow: hidden;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	img {
		width: 60px;
		height: 60px;
		float: left;
		border-radius: 30px;
	}
	.custom-alert-icon+.alert-content {
		padding-left: 20px;
		float: right;
		width: calc(100% - 60px);
	}
	img+.alert-content {
		padding-left: 20px;
		float: right;
		width: calc(100% - 60px);
	}
	.alert-content {
		padding: 8px 10px;
		overflow: hidden;
		.alert-title {
			font-size: 13px;
			font-weight: 700;
			margin-bottom: 5px;
			display: block;
		}
		.alert-text {
			font-size: 13px;
			display: block;
		}
	}
}
.alert.alert-custom {
	.custom-alert-icon {
		width: 60px;
		height: 60px;
		float: left;
		border-radius: 30px;
		font-size: 14px;
		text-align: center;
		i {
			line-height: 60px;
		}
	}
	.custom-alert-icon.icon-primary {
		background: #dff0fe;
		color: $color_19;
	}
	.custom-alert-icon.icon-success {
		background: #e6fcf0;
		color: $color_7;
	}
	.custom-alert-icon.icon-danger {
		background: #fce3e5;
		color: $color_8;
	}
	.custom-alert-icon.icon-warning {
		background: #fff3e3;
		color: $color_9;
	}
	.custom-alert-icon.icon-info {
		background: #e0efff;
		color: $color_10;
	}
	.custom-alert-icon.icon-dark {
		background: #40475c;
		color: $color_23;
	}
}
.alert-custom.alert-indicator-bottom {
	border-radius: 5px;
	&::before {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 3px;
		content: ' ';
	}
}
.alert-custom.alert-indicator-left {
	border-radius: 5px;
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 3px;
		content: ' ';
	}
}
.alert-custom.alert-indicator-right {
	border-radius: 5px;
	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 3px;
		content: ' ';
	}
}
.alert-custom.alert-indicator-top {
	border-radius: 5px;
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 3px;
		content: ' ';
	}
}
.alert-custom.indicator-success {
	&::before {
		background: #4bad48;
	}
}
.alert-custom.indicator-danger {
	&::before {
		background: #ff4857;
	}
}
.alert-custom.indicator-info {
	&::before {
		background: #61acfc;
	}
}
.alert-custom.indicator-warning {
	&::before {
		background: #ff9500;
	}
}
.avatar {
	border-radius: 5px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	font-size: 18px;
	font-weight: 700;
	color: $color_41;
	display: inline-block;
	position: relative;
	img {
		width: inherit;
		height: inherit;
		border-radius: inherit;
	}
	.avatar-title {
		width: inherit;
		height: inherit;
		background-color: $background-color_20;
		border-radius: inherit;
		text-align: center;
	}
	.badge {
		position: absolute;
		top: -5px;
		right: -5px;
		font-size: 11px;
	}
}
.avatar-xxl {
	width: 100px;
	height: 100px;
	line-height: 100px;
	font-size: 32px;
}
.avatar-xl {
	width: 80px;
	height: 80px;
	line-height: 80px;
	font-size: 24px;
}
.avatar-sm {
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 16px;
}
.avatar-xs {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
}
.avatar.status {
	&::after {
		content: '';
		display: block;
		position: absolute;
		width: 16px;
		height: 16px;
		background: #364459;
		border-radius: 8px;
		border: 2px solid #fff;
		bottom: -4px;
		right: -4px;
	}
}
.avatar.status.status-online {
	&::after {
		background: #4bad48;
	}
}
.avatar.status.status-offline {
	&::after {
		background: #ff4857;
	}
}
.avatar.avatar-xxl.status {
	&::after {
		width: 24px;
		height: 24px;
		border-radius: 12px;
		bottom: -6px;
		right: -6px;
		border-width: 3px;
	}
}
.avatar.avatar-xl.status {
	&::after {
		width: 20px;
		height: 20px;
		border-radius: 10px;
		border-width: 3px;
	}
}
.avatar.avatar-sm.status {
	&::after {
		width: 14px;
		height: 14px;
		border-radius: 7px;
	}
}
.avatar.avatar-xs.status {
	&::after {
		width: 12px;
		height: 12px;
		border-radius: 6px;
	}
}
.avatar.avatar-rounded {
	border-radius: 50% !important;
}
.avatar-container {
	.avatar {
		-webkit-transition: all .2s ease-in-out !important;
		-moz-transition: all .2s ease-in-out !important;
		-o-transition: all .2s ease-in-out !important;
		transition: all .2s ease-in-out !important;
		&:hover {
			transform: translateY(-7px);
		}
		>* {
			border: 3px solid #fff;
		}
		&:not(:first-child) {
			margin-left: -15px;
		}
	}
}
.badge.badge-primary {
	background: #2269f5;
	color: $color_23;
}
.badge.badge-secondary {
	color: $color_40;
	background: #fceace;
}
.badge.badge-success {
	background: #4bad48;
	color: $color_23;
}
.badge.badge-danger {
	background: #ff4857;
	color: $color_23;
}
.badge.badge-warning {
	background: #ff9500;
	color: $color_23;
}
.badge.badge-info {
	background: #61acfc;
	color: $color_23;
}
.badge.badge-light {
	background: #f4f7fa;
	color: $color_40;
}
.badge.badge-dark {
	background: #40475c;
	color: $color_23;
}
.badge.badge-style-light.badge-primary {
	background: #dff0fe;
	color: $color_19;
}
.badge.badge-style-light.badge-success {
	background: #e6fcf0;
	color: $color_7;
}
.badge.badge-style-light.badge-danger {
	background: #fce3e5;
	color: $color_8;
}
.badge.badge-style-light.badge-warning {
	background: #fff3e3;
	color: $color_9;
}
.badge.badge-style-light.badge-info {
	background: #e0efff;
	color: $color_10;
}
.badge.badge-style-light.badge-dark {
	background: #d4d4d4;
	color: $color_17;
}
.badge.badge-style-bordered {
	background-color: $background-color_14;
	border: 1px solid #f1f1f1;
}
.badge.badge-style-bordered.badge-primary {
	border-color: $border-color_8;
	color: $color_19;
}
.badge.badge-style-bordered.badge-success {
	border-color: $border-color_4;
	color: $color_7;
}
.badge.badge-style-bordered.badge-danger {
	border-color: $border-color_9;
	color: $color_8;
}
.badge.badge-style-bordered.badge-warning {
	border-color: $border-color_10;
	color: $color_9;
}
.badge.badge-style-bordered.badge-info {
	border-color: $border-color_11;
	color: $color_10;
}
.badge.badge-style-bordered.badge-dark {
	border-color: $border-color_12;
	color: $color_17;
}
a.badge {
	text-decoration: none;
}
.breadcrumb.breadcrumb-container {
	padding: 12px 30px;
	background: #f4f7fa;
	border-radius: 5px;
}
.breadcrumb {
	.breadcrumb-item {
		a {
			text-decoration: none;
		}
		&::before {
			color: $color_42;
		}
	}
	.breadcrumb-item.active {
		color: $color_42;
	}
}
.breadcrumb.bg-secondary {
	.breadcrumb-item {
		a {
			color: $color_40;
		}
	}
}
.breadcrumb.bg-danger {
	.breadcrumb-item {
		a {
			color: $color_23;
		}
		&::before {
			color: $color_43;
		}
	}
	.breadcrumb-item.active {
		color: $color_44;
	}
}
.breadcrumb.bg-dark {
	.breadcrumb-item {
		a {
			color: $color_23;
		}
		&::before {
			color: $color_43;
		}
	}
	.breadcrumb-item.active {
		color: $color_44;
	}
}
.breadcrumb.bg-info {
	.breadcrumb-item {
		a {
			color: $color_23;
		}
		&::before {
			color: $color_43;
		}
	}
	.breadcrumb-item.active {
		color: $color_44;
	}
}
.breadcrumb.bg-primary {
	.breadcrumb-item {
		a {
			color: $color_23;
		}
		&::before {
			color: $color_43;
		}
	}
	.breadcrumb-item.active {
		color: $color_44;
	}
}
.breadcrumb.bg-success {
	.breadcrumb-item {
		a {
			color: $color_23;
		}
		&::before {
			color: $color_43;
		}
	}
	.breadcrumb-item.active {
		color: $color_44;
	}
}
.breadcrumb.bg-warning {
	.breadcrumb-item {
		a {
			color: $color_23;
		}
		&::before {
			color: $color_43;
		}
	}
	.breadcrumb-item.active {
		color: $color_44;
	}
}
.breadcrumb.breadcrumb-container.breadcrumb-container-light.bg-primary {
	background-color: $background-color_21 !important;
	.breadcrumb-item {
		a {
			color: $color_19;
		}
		&::before {
			color: $color_19;
		}
	}
	.breadcrumb-item.active {
		color: $color_19;
	}
}
.breadcrumb.breadcrumb-container.breadcrumb-container-light.bg-success {
	background-color: $background-color_22 !important;
	.breadcrumb-item {
		a {
			color: $color_7;
		}
		&::before {
			color: $color_7;
		}
	}
	.breadcrumb-item.active {
		color: $color_7;
	}
}
.breadcrumb.breadcrumb-container.breadcrumb-container-light.bg-danger {
	background-color: $background-color_23 !important;
	.breadcrumb-item {
		a {
			color: $color_8;
		}
		&::before {
			color: $color_8;
		}
	}
	.breadcrumb-item.active {
		color: $color_8;
	}
}
.breadcrumb.breadcrumb-container.breadcrumb-container-light.bg-warning {
	background-color: $background-color_24 !important;
	.breadcrumb-item {
		a {
			color: $color_9;
		}
		&::before {
			color: $color_9;
		}
	}
	.breadcrumb-item.active {
		color: $color_9;
	}
}
.breadcrumb.breadcrumb-container.breadcrumb-container-light.bg-info {
	background-color: $background-color_25 !important;
	.breadcrumb-item {
		a {
			color: $color_10;
		}
		&::before {
			color: $color_10;
		}
	}
	.breadcrumb-item.active {
		color: $color_10;
	}
}
.breadcrumb.breadcrumb-container.breadcrumb-container-light {
	.breadcrumb-item.active {
		opacity: .5;
	}
	.breadcrumb-item {
		&:not(.active) {
			&::before {
				opacity: .5;
			}
		}
	}
}
.breadcrumb.breadcrumb-separator-chevron {
	.breadcrumb-item+.breadcrumb-item {
		&::before {
			font-family: $font-family_3;
			content: var(--bs-breadcrumb-divider, "\e5cc");
		}
	}
}
.breadcrumb.breadcrumb-separator-arrow {
	.breadcrumb-item+.breadcrumb-item {
		&::before {
			font-family: $font-family_3;
			content: var(--bs-breadcrumb-divider, "\e5c8");
		}
	}
}
.breadcrumb.breadcrumb-separator-dot {
	.breadcrumb-item+.breadcrumb-item {
		&::before {
			font-family: $font-family_3;
			content: var(--bs-breadcrumb-divider, "\e061");
			font-size: 6px;
			line-height: 22px;
			margin: 0 4px;
		}
	}
}
.btn {
	display: inline-block;
	padding: 6px 20px;
	border-radius: 5px;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: all .2s ease-in-out !important;
	-moz-transition: all .2s ease-in-out !important;
	-o-transition: all .2s ease-in-out !important;
	transition: all .2s ease-in-out !important;
	i {
		vertical-align: text-top;
		line-height: 21px;
		margin-right: 6px;
		margin-left: -8px;
		font-size: 20px;
	}
	&:active {
		box-shadow: none !important;
	}
	&:focus {
		box-shadow: none !important;
	}
	.spinner-border {
		vertical-align: middle;
	}
	.spinner-grow {
		vertical-align: middle;
	}
}
.btn-group-lg {
	.btn {
		padding: 9px 30px;
		font-size: 16px;
	}
	.dropdown-toggle-split {
		padding: 9px 15px;
	}
}
.btn-lg {
	padding: 9px 30px;
	font-size: 16px;
	i {
		line-height: 24px;
	}
}
.btn-group-sm {
	.btn {
		padding: 4px 12px;
		font-size: 13px;
	}
}
.btn-sm {
	padding: 4px 12px;
	font-size: 13px;
	i {
		margin-right: 2px;
		line-height: 19px;
		font-size: 20px;
	}
}
.dropdown-toggle-split {
	padding: 6px 10px;
}
.btn-rounded {
	border-radius: 18px;
}
.btn-lg.btn-rounded {
	border-radius: 22px;
}
.btn-sm.btn-rounded {
	border-radius: 15px;
}
.btn-check {
	&:active+.btn {
		box-shadow: none !important;
	}
	&:checked+.btn {
		box-shadow: none !important;
	}
	&:focus+.btn {
		box-shadow: none !important;
	}
	&:active+.btn-outline-primary {
		color: $color_23;
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	&:checked+.btn-outline-primary {
		color: $color_23;
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	&:active+.btn-outline-secondary {
		color: $color_40;
		background-color: $background-color_3;
		border-color: $border-color_14;
	}
	&:checked+.btn-outline-secondary {
		color: $color_40;
		background-color: $background-color_3;
		border-color: $border-color_14;
	}
	&:active+.btn-outline-success {
		color: $color_23;
		background-color: $background-color_4;
		border-color: $border-color_4;
	}
	&:checked+.btn-outline-success {
		color: $color_23;
		background-color: $background-color_4;
		border-color: $border-color_4;
	}
	&:active+.btn-outline-danger {
		color: $color_23;
		background-color: $background-color_5;
		border-color: $border-color_9;
	}
	&:checked+.btn-outline-danger {
		color: $color_23;
		background-color: $background-color_5;
		border-color: $border-color_9;
	}
	&:active+.btn-outline-warning {
		color: $color_23;
		background-color: $background-color_6;
		border-color: $border-color_10;
	}
	&:checked+.btn-outline-warning {
		color: $color_23;
		background-color: $background-color_6;
		border-color: $border-color_10;
	}
	&:active+.btn-outline-info {
		color: $color_23;
		background-color: $background-color_7;
		border-color: $border-color_11;
	}
	&:checked+.btn-outline-info {
		color: $color_23;
		background-color: $background-color_7;
		border-color: $border-color_11;
	}
	&:active+.btn-outline-light {
		color: $color_40;
		background-color: $background-color_8;
		border-color: $border-color_20;
	}
	&:checked+.btn-outline-light {
		color: $color_40;
		background-color: $background-color_8;
		border-color: $border-color_20;
	}
	&:active+.btn-outline-dark {
		color: $color_23;
		background-color: $background-color_18;
		border-color: $border-color_12;
	}
	&:checked+.btn-outline-dark {
		color: $color_23;
		background-color: $background-color_18;
		border-color: $border-color_12;
	}
}
.btn-danger {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none !important;
			}
			color: $color_23;
			background-color: $background-color_30;
			border-color: $border-color_17;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none !important;
				}
				color: $color_23;
				background-color: $background-color_30;
				border-color: $border-color_17;
			}
		}
	}
	color: $color_23;
	background-color: $background-color_5;
	border-color: $border-color_9;
	&:disabled {
		color: $color_23;
		background-color: $background-color_5;
		border-color: $border-color_9;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_30;
		border-color: $border-color_17;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_30;
		border-color: $border-color_17;
	}
}
.btn-dark {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none !important;
			}
			color: $color_23;
			background-color: $background-color_34;
			border-color: $border-color_22;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none !important;
				}
				color: $color_23;
				background-color: $background-color_34;
				border-color: $border-color_22;
			}
		}
	}
	color: $color_23;
	background-color: $background-color_18;
	border-color: $border-color_12;
	&:disabled {
		color: $color_23;
		background-color: $background-color_18;
		border-color: $border-color_12;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_34;
		border-color: $border-color_22;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_34;
		border-color: $border-color_22;
	}
}
.btn-info {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none !important;
			}
			color: $color_23;
			background-color: $background-color_32;
			border-color: $border-color_19;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none !important;
				}
				color: $color_23;
				background-color: $background-color_32;
				border-color: $border-color_19;
			}
		}
	}
	color: $color_23;
	background-color: $background-color_7;
	border-color: $border-color_11;
	&:disabled {
		color: $color_23;
		background-color: $background-color_7;
		border-color: $border-color_11;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_32;
		border-color: $border-color_19;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_32;
		border-color: $border-color_19;
	}
}
.btn-light {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none !important;
			}
			color: $color_40;
			background-color: $background-color_33;
			border-color: $border-color_21;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none !important;
				}
				color: $color_40;
				background-color: $background-color_33;
				border-color: $border-color_21;
			}
		}
	}
	color: $color_40;
	background-color: $background-color_8;
	border-color: $border-color_20;
	&:disabled {
		color: $color_40;
		background-color: $background-color_8;
		border-color: $border-color_20;
	}
	&:focus {
		color: $color_40;
		background-color: $background-color_33;
		border-color: $border-color_21;
	}
	&:hover {
		color: $color_40;
		background-color: $background-color_33;
		border-color: $border-color_21;
	}
}
.btn-link {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none !important;
			}
			color: $color_45;
			background-color: $background-color_21;
			border-color: $border-color_24;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none !important;
				}
				color: $color_45;
				background-color: $background-color_21;
				border-color: $border-color_24;
			}
		}
	}
	color: $color_2;
	background-color: $background-color_14;
	border-color: $border-color_23;
	text-decoration: none;
	&:disabled {
		color: $color_2;
		background-color: $background-color_14;
		border-color: $border-color_23;
		text-decoration: none;
	}
	&:focus {
		color: $color_45;
		background-color: $background-color_21;
		border-color: $border-color_24;
	}
	&:hover {
		color: $color_45;
		background-color: $background-color_21;
		border-color: $border-color_24;
	}
}
.btn-primary {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none !important;
			}
			color: $color_23;
			background-color: $background-color_27;
			border-color: $border-color_13;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none !important;
				}
				color: $color_23;
				background-color: $background-color_27;
				border-color: $border-color_13;
			}
		}
	}
	color: $color_23;
	background-color: $background-color_26;
	border-color: $border-color_8;
	&:disabled {
		color: $color_23;
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_27;
		border-color: $border-color_13;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_27;
		border-color: $border-color_13;
	}
}
.btn-secondary {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none !important;
			}
			color: $color_40;
			background-color: $background-color_28;
			border-color: $border-color_15;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none !important;
				}
				color: $color_40;
				background-color: $background-color_28;
				border-color: $border-color_15;
			}
		}
	}
	color: $color_40;
	background-color: $background-color_3;
	border-color: $border-color_14;
	&:disabled {
		color: $color_40;
		background-color: $background-color_3;
		border-color: $border-color_14;
	}
	&:focus {
		color: $color_40;
		background-color: $background-color_28;
		border-color: $border-color_15;
	}
	&:hover {
		color: $color_40;
		background-color: $background-color_28;
		border-color: $border-color_15;
	}
}
.btn-success {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none !important;
			}
			color: $color_23;
			background-color: $background-color_29;
			border-color: $border-color_16;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none !important;
				}
				color: $color_23;
				background-color: $background-color_29;
				border-color: $border-color_16;
			}
		}
	}
	color: $color_23;
	background-color: $background-color_4;
	border-color: $border-color_4;
	&:disabled {
		color: $color_23;
		background-color: $background-color_4;
		border-color: $border-color_4;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_29;
		border-color: $border-color_16;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_29;
		border-color: $border-color_16;
	}
}
.btn-warning {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none !important;
			}
			color: $color_23;
			background-color: $background-color_31;
			border-color: $border-color_18;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none !important;
				}
				color: $color_23;
				background-color: $background-color_31;
				border-color: $border-color_18;
			}
		}
	}
	color: $color_23;
	background-color: $background-color_6;
	border-color: $border-color_10;
	&:disabled {
		color: $color_23;
		background-color: $background-color_6;
		border-color: $border-color_10;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_31;
		border-color: $border-color_18;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_31;
		border-color: $border-color_18;
	}
}
.btn.active {
	box-shadow: none !important;
}
.btn.dropdown-toggle.show {
	box-shadow: none !important;
}
.show {
	>.btn-danger.dropdown-toggle {
		&:focus {
			box-shadow: none !important;
		}
		color: $color_23;
		background-color: $background-color_30;
		border-color: $border-color_17;
	}
	>.btn-dark.dropdown-toggle {
		&:focus {
			box-shadow: none !important;
		}
		color: $color_23;
		background-color: $background-color_34;
		border-color: $border-color_22;
	}
	>.btn-info.dropdown-toggle {
		&:focus {
			box-shadow: none !important;
		}
		color: $color_23;
		background-color: $background-color_32;
		border-color: $border-color_19;
	}
	>.btn-light.dropdown-toggle {
		&:focus {
			box-shadow: none !important;
		}
		color: $color_40;
		background-color: $background-color_33;
		border-color: $border-color_21;
	}
	>.btn-link.dropdown-toggle {
		&:focus {
			box-shadow: none !important;
		}
		color: $color_45;
		background-color: $background-color_21;
		border-color: $border-color_24;
	}
	>.btn-primary.dropdown-toggle {
		&:focus {
			box-shadow: none !important;
		}
		color: $color_23;
		background-color: $background-color_27;
		border-color: $border-color_13;
	}
	>.btn-secondary.dropdown-toggle {
		&:focus {
			box-shadow: none !important;
		}
		color: $color_40;
		background-color: $background-color_28;
		border-color: $border-color_15;
	}
	>.btn-success.dropdown-toggle {
		&:focus {
			box-shadow: none !important;
		}
		color: $color_23;
		background-color: $background-color_29;
		border-color: $border-color_16;
	}
	>.btn-warning.dropdown-toggle {
		&:focus {
			box-shadow: none !important;
		}
		color: $color_23;
		background-color: $background-color_31;
		border-color: $border-color_18;
	}
	>.btn-style-light.btn-primary.dropdown-toggle {
		color: $color_19;
		background-color: $background-color_35;
		color: $color_10;
		background-color: $background-color_39;
	}
	>.btn-style-light.btn-success.dropdown-toggle {
		color: $color_7;
		background-color: $background-color_36;
	}
	>.btn-style-light.btn-danger.dropdown-toggle {
		color: $color_8;
		background-color: $background-color_37;
	}
	>.btn-style-light.btn-warning.dropdown-toggle {
		color: $color_9;
		background-color: $background-color_38;
	}
	>.btn-style-light.btn-dark.dropdown-toggle {
		color: $color_17;
		background-color: $background-color_40;
	}
	>.btn-outline-primary.dropdown-toggle {
		color: $color_23;
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	>.btn-outline-secondary.dropdown-toggle {
		color: $color_40;
		background-color: $background-color_3;
		border-color: $border-color_14;
	}
	>.btn-outline-success.dropdown-toggle {
		color: $color_23;
		background-color: $background-color_4;
		border-color: $border-color_4;
	}
	>.btn-outline-danger.dropdown-toggle {
		color: $color_23;
		background-color: $background-color_5;
		border-color: $border-color_9;
	}
	>.btn-outline-warning.dropdown-toggle {
		color: $color_23;
		background-color: $background-color_6;
		border-color: $border-color_10;
	}
	>.btn-outline-info.dropdown-toggle {
		color: $color_23;
		background-color: $background-color_7;
		border-color: $border-color_11;
	}
	>.btn-outline-light.dropdown-toggle {
		color: $color_40;
		background-color: $background-color_8;
		border-color: $border-color_20;
	}
	>.btn-outline-dark.dropdown-toggle {
		color: $color_23;
		background-color: $background-color_18;
		border-color: $border-color_12;
	}
}
.btn-primary.disabled {
	color: $color_23;
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.btn-primary.focus {
	color: $color_23;
	background-color: $background-color_27;
	border-color: $border-color_13;
}
.btn-secondary.disabled {
	color: $color_40;
	background-color: $background-color_3;
	border-color: $border-color_14;
}
.btn-secondary.focus {
	color: $color_40;
	background-color: $background-color_28;
	border-color: $border-color_15;
}
.btn-success.disabled {
	color: $color_23;
	background-color: $background-color_4;
	border-color: $border-color_4;
}
.btn-success.focus {
	color: $color_23;
	background-color: $background-color_29;
	border-color: $border-color_16;
}
.btn-danger.disabled {
	color: $color_23;
	background-color: $background-color_5;
	border-color: $border-color_9;
}
.btn-danger.focus {
	color: $color_23;
	background-color: $background-color_30;
	border-color: $border-color_17;
}
.btn-warning.disabled {
	color: $color_23;
	background-color: $background-color_6;
	border-color: $border-color_10;
}
.btn-warning.focus {
	color: $color_23;
	background-color: $background-color_31;
	border-color: $border-color_18;
}
.btn-info.disabled {
	color: $color_23;
	background-color: $background-color_7;
	border-color: $border-color_11;
}
.btn-info.focus {
	color: $color_23;
	background-color: $background-color_32;
	border-color: $border-color_19;
}
.btn-light.disabled {
	color: $color_40;
	background-color: $background-color_8;
	border-color: $border-color_20;
}
.btn-light.focus {
	color: $color_40;
	background-color: $background-color_33;
	border-color: $border-color_21;
}
.btn-dark.disabled {
	color: $color_23;
	background-color: $background-color_18;
	border-color: $border-color_12;
}
.btn-dark.focus {
	color: $color_23;
	background-color: $background-color_34;
	border-color: $border-color_22;
}
.btn-link.disabled {
	color: $color_2;
	background-color: $background-color_14;
	border-color: $border-color_23;
	text-decoration: none;
}
.btn-link.focus {
	color: $color_45;
	background-color: $background-color_21;
	border-color: $border-color_24;
}
.btn-style-light {
	border: none !important;
	&:active {
		border: none !important;
	}
	&:focus {
		border: none !important;
	}
}
.btn-style-light.active {
	border: none !important;
}
.btn-style-light.btn-primary {
	background: #dff0fe;
	color: $color_19;
	&:disabled {
		background: #dff0fe;
		color: $color_19;
	}
	&:focus {
		color: $color_19;
		background-color: $background-color_35;
	}
	&:hover {
		color: $color_19;
		background-color: $background-color_35;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_19;
			background-color: $background-color_35;
		}
		&:not(.disabled) {
			&:active {
				color: $color_19;
				background-color: $background-color_35;
				color: $color_10;
				background-color: $background-color_39;
			}
		}
	}
}
.btn-style-light.btn-primary.disabled {
	background: #dff0fe;
	color: $color_19;
}
.btn-style-light.btn-primary.focus {
	color: $color_19;
	background-color: $background-color_35;
}
.btn-style-light.btn-success {
	background: #e6fcf0;
	color: $color_7;
	&:disabled {
		background: #e6fcf0;
		color: $color_7;
	}
	&:focus {
		color: $color_7;
		background-color: $background-color_36;
	}
	&:hover {
		color: $color_7;
		background-color: $background-color_36;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_7;
			background-color: $background-color_36;
		}
		&:not(.disabled) {
			&:active {
				color: $color_7;
				background-color: $background-color_36;
			}
		}
	}
}
.btn-style-light.btn-success.disabled {
	background: #e6fcf0;
	color: $color_7;
}
.btn-style-light.btn-success.focus {
	color: $color_7;
	background-color: $background-color_36;
}
.btn-style-light.btn-danger {
	background: #fce3e5;
	color: $color_8;
	&:disabled {
		background: #fce3e5;
		color: $color_8;
	}
	&:focus {
		color: $color_8;
		background-color: $background-color_37;
	}
	&:hover {
		color: $color_8;
		background-color: $background-color_37;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_8;
			background-color: $background-color_37;
		}
		&:not(.disabled) {
			&:active {
				color: $color_8;
				background-color: $background-color_37;
			}
		}
	}
}
.btn-style-light.btn-danger.disabled {
	background: #fce3e5;
	color: $color_8;
}
.btn-style-light.btn-danger.focus {
	color: $color_8;
	background-color: $background-color_37;
}
.btn-style-light.btn-warning {
	background: #fff3e3;
	color: $color_9;
	&:disabled {
		background: #fff3e3;
		color: $color_9;
	}
	&:focus {
		color: $color_9;
		background-color: $background-color_38;
	}
	&:hover {
		color: $color_9;
		background-color: $background-color_38;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_9;
			background-color: $background-color_38;
		}
		&:not(.disabled) {
			&:active {
				color: $color_9;
				background-color: $background-color_38;
			}
		}
	}
}
.btn-style-light.btn-warning.disabled {
	background: #fff3e3;
	color: $color_9;
}
.btn-style-light.btn-warning.focus {
	color: $color_9;
	background-color: $background-color_38;
}
.btn-style-light.btn-info {
	background: #e0efff;
	color: $color_10;
	&:disabled {
		background: #e0efff;
		color: $color_10;
	}
	&:focus {
		color: $color_10;
		background-color: $background-color_39;
	}
	&:hover {
		color: $color_10;
		background-color: $background-color_39;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_10;
			background-color: $background-color_39;
		}
	}
}
.btn-style-light.btn-info.disabled {
	background: #e0efff;
	color: $color_10;
}
.btn-style-light.btn-info.focus {
	color: $color_10;
	background-color: $background-color_39;
}
.btn-style-light.btn-dark {
	background: #d4d4d4;
	color: $color_17;
	&:disabled {
		background: #d4d4d4;
		color: $color_17;
	}
	&:focus {
		color: $color_17;
		background-color: $background-color_40;
	}
	&:hover {
		color: $color_17;
		background-color: $background-color_40;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_17;
			background-color: $background-color_40;
		}
		&:not(.disabled) {
			&:active {
				color: $color_17;
				background-color: $background-color_40;
			}
		}
	}
}
.btn-style-light.btn-dark.disabled {
	background: #d4d4d4;
	color: $color_17;
}
.btn-style-light.btn-dark.focus {
	color: $color_17;
	background-color: $background-color_40;
}
.btn-outline-primary {
	color: $color_19;
	background-color: $background-color_14;
	border-color: $border-color_8;
	&:disabled {
		color: $color_19;
		background-color: $background-color_14;
		border-color: $border-color_8;
	}
	&:active {
		color: $color_23;
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_23;
			background-color: $background-color_26;
			border-color: $border-color_8;
		}
		&:not(.disabled) {
			&:active {
				color: $color_23;
				background-color: $background-color_26;
				border-color: $border-color_8;
			}
		}
	}
}
.btn-outline-primary.disabled {
	color: $color_19;
	background-color: $background-color_14;
	border-color: $border-color_8;
}
.btn-outline-primary.active {
	color: $color_23;
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.btn-outline-primary.dropdown-toggle.show {
	color: $color_23;
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.btn-outline-primary.focus {
	color: $color_23;
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.btn-outline-secondary {
	color: $color_40;
	background-color: $background-color_14;
	border-color: $border-color_14;
	&:disabled {
		color: $color_40;
		background-color: $background-color_14;
		border-color: $border-color_14;
	}
	&:active {
		color: $color_40;
		background-color: $background-color_3;
		border-color: $border-color_14;
	}
	&:focus {
		color: $color_40;
		background-color: $background-color_3;
		border-color: $border-color_14;
	}
	&:hover {
		color: $color_40;
		background-color: $background-color_3;
		border-color: $border-color_14;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_40;
			background-color: $background-color_3;
			border-color: $border-color_14;
		}
		&:not(.disabled) {
			&:active {
				color: $color_40;
				background-color: $background-color_3;
				border-color: $border-color_14;
			}
		}
	}
}
.btn-outline-secondary.disabled {
	color: $color_40;
	background-color: $background-color_14;
	border-color: $border-color_14;
}
.btn-outline-secondary.active {
	color: $color_40;
	background-color: $background-color_3;
	border-color: $border-color_14;
}
.btn-outline-secondary.dropdown-toggle.show {
	color: $color_40;
	background-color: $background-color_3;
	border-color: $border-color_14;
}
.btn-outline-secondary.focus {
	color: $color_40;
	background-color: $background-color_3;
	border-color: $border-color_14;
}
.btn-outline-success {
	color: $color_7;
	background-color: $background-color_14;
	border-color: $border-color_4;
	&:disabled {
		color: $color_7;
		background-color: $background-color_14;
		border-color: $border-color_4;
	}
	&:active {
		color: $color_23;
		background-color: $background-color_4;
		border-color: $border-color_4;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_4;
		border-color: $border-color_4;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_4;
		border-color: $border-color_4;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_23;
			background-color: $background-color_4;
			border-color: $border-color_4;
		}
		&:not(.disabled) {
			&:active {
				color: $color_23;
				background-color: $background-color_4;
				border-color: $border-color_4;
			}
		}
	}
}
.btn-outline-success.disabled {
	color: $color_7;
	background-color: $background-color_14;
	border-color: $border-color_4;
}
.btn-outline-success.active {
	color: $color_23;
	background-color: $background-color_4;
	border-color: $border-color_4;
}
.btn-outline-success.dropdown-toggle.show {
	color: $color_23;
	background-color: $background-color_4;
	border-color: $border-color_4;
}
.btn-outline-success.focus {
	color: $color_23;
	background-color: $background-color_4;
	border-color: $border-color_4;
}
.btn-outline-danger {
	color: $color_8;
	background-color: $background-color_14;
	border-color: $border-color_9;
	&:disabled {
		color: $color_8;
		background-color: $background-color_14;
		border-color: $border-color_9;
	}
	&:active {
		color: $color_23;
		background-color: $background-color_5;
		border-color: $border-color_9;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_5;
		border-color: $border-color_9;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_5;
		border-color: $border-color_9;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_23;
			background-color: $background-color_5;
			border-color: $border-color_9;
		}
		&:not(.disabled) {
			&:active {
				color: $color_23;
				background-color: $background-color_5;
				border-color: $border-color_9;
			}
		}
	}
}
.btn-outline-danger.disabled {
	color: $color_8;
	background-color: $background-color_14;
	border-color: $border-color_9;
}
.btn-outline-danger.active {
	color: $color_23;
	background-color: $background-color_5;
	border-color: $border-color_9;
}
.btn-outline-danger.dropdown-toggle.show {
	color: $color_23;
	background-color: $background-color_5;
	border-color: $border-color_9;
}
.btn-outline-danger.focus {
	color: $color_23;
	background-color: $background-color_5;
	border-color: $border-color_9;
}
.btn-outline-warning {
	color: $color_9;
	background-color: $background-color_14;
	border-color: $border-color_10;
	&:disabled {
		color: $color_9;
		background-color: $background-color_14;
		border-color: $border-color_10;
	}
	&:active {
		color: $color_23;
		background-color: $background-color_6;
		border-color: $border-color_10;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_6;
		border-color: $border-color_10;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_6;
		border-color: $border-color_10;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_23;
			background-color: $background-color_6;
			border-color: $border-color_10;
		}
		&:not(.disabled) {
			&:active {
				color: $color_23;
				background-color: $background-color_6;
				border-color: $border-color_10;
			}
		}
	}
}
.btn-outline-warning.disabled {
	color: $color_9;
	background-color: $background-color_14;
	border-color: $border-color_10;
}
.btn-outline-warning.active {
	color: $color_23;
	background-color: $background-color_6;
	border-color: $border-color_10;
}
.btn-outline-warning.dropdown-toggle.show {
	color: $color_23;
	background-color: $background-color_6;
	border-color: $border-color_10;
}
.btn-outline-warning.focus {
	color: $color_23;
	background-color: $background-color_6;
	border-color: $border-color_10;
}
.btn-outline-info {
	color: $color_10;
	background-color: $background-color_14;
	border-color: $border-color_11;
	&:disabled {
		color: $color_10;
		background-color: $background-color_14;
		border-color: $border-color_11;
	}
	&:active {
		color: $color_23;
		background-color: $background-color_7;
		border-color: $border-color_11;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_7;
		border-color: $border-color_11;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_7;
		border-color: $border-color_11;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_23;
			background-color: $background-color_7;
			border-color: $border-color_11;
		}
		&:not(.disabled) {
			&:active {
				color: $color_23;
				background-color: $background-color_7;
				border-color: $border-color_11;
			}
		}
	}
}
.btn-outline-info.disabled {
	color: $color_10;
	background-color: $background-color_14;
	border-color: $border-color_11;
}
.btn-outline-info.active {
	color: $color_23;
	background-color: $background-color_7;
	border-color: $border-color_11;
}
.btn-outline-info.dropdown-toggle.show {
	color: $color_23;
	background-color: $background-color_7;
	border-color: $border-color_11;
}
.btn-outline-info.focus {
	color: $color_23;
	background-color: $background-color_7;
	border-color: $border-color_11;
}
.btn-outline-light {
	color: $color_11;
	background-color: $background-color_14;
	border-color: $border-color_20;
	&:disabled {
		color: $color_11;
		background-color: $background-color_14;
		border-color: $border-color_20;
	}
	&:active {
		color: $color_40;
		background-color: $background-color_8;
		border-color: $border-color_20;
	}
	&:focus {
		color: $color_40;
		background-color: $background-color_8;
		border-color: $border-color_20;
	}
	&:hover {
		color: $color_40;
		background-color: $background-color_8;
		border-color: $border-color_20;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_40;
			background-color: $background-color_8;
			border-color: $border-color_20;
		}
		&:not(.disabled) {
			&:active {
				color: $color_40;
				background-color: $background-color_8;
				border-color: $border-color_20;
			}
		}
	}
}
.btn-outline-light.disabled {
	color: $color_11;
	background-color: $background-color_14;
	border-color: $border-color_20;
}
.btn-outline-light.active {
	color: $color_40;
	background-color: $background-color_8;
	border-color: $border-color_20;
}
.btn-outline-light.dropdown-toggle.show {
	color: $color_40;
	background-color: $background-color_8;
	border-color: $border-color_20;
}
.btn-outline-light.focus {
	color: $color_40;
	background-color: $background-color_8;
	border-color: $border-color_20;
}
.btn-outline-dark {
	color: $color_17;
	background-color: $background-color_14;
	border-color: $border-color_12;
	&:disabled {
		color: $color_17;
		background-color: $background-color_14;
		border-color: $border-color_12;
	}
	&:active {
		color: $color_23;
		background-color: $background-color_18;
		border-color: $border-color_12;
	}
	&:focus {
		color: $color_23;
		background-color: $background-color_18;
		border-color: $border-color_12;
	}
	&:hover {
		color: $color_23;
		background-color: $background-color_18;
		border-color: $border-color_12;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $color_23;
			background-color: $background-color_18;
			border-color: $border-color_12;
		}
		&:not(.disabled) {
			&:active {
				color: $color_23;
				background-color: $background-color_18;
				border-color: $border-color_12;
			}
		}
	}
}
.btn-outline-dark.disabled {
	color: $color_17;
	background-color: $background-color_14;
	border-color: $border-color_12;
}
.btn-outline-dark.active {
	color: $color_23;
	background-color: $background-color_18;
	border-color: $border-color_12;
}
.btn-outline-dark.dropdown-toggle.show {
	color: $color_23;
	background-color: $background-color_18;
	border-color: $border-color_12;
}
.btn-outline-dark.focus {
	color: $color_23;
	background-color: $background-color_18;
	border-color: $border-color_12;
}
.btn-burger {
	width: 55px;
	height: 55px;
	line-height: 55px !important;
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%),0 2px 10px 0 rgb(0 0 0 / 12%);
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 0;
	border-radius: 50%;
	cursor: pointer;
	vertical-align: middle !important;
	&:active {
		box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%),0 2px 10px 0 rgb(0 0 0 / 12%) !important;
	}
	&:focus {
		box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%),0 2px 10px 0 rgb(0 0 0 / 12%) !important;
	}
	i {
		vertical-align: middle !important;
		line-height: 55px !important;
		margin: 0 !important;
		font-size: 24px !important;
	}
}
.btn-burger.active {
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%),0 2px 10px 0 rgb(0 0 0 / 12%) !important;
}
.img-thumbnail {
	border-radius: 5px;
	padding: 5px;
}
.pagination {
	margin-bottom: 0;
}
.page-item.disabled {
	.page-link {
		background: #f4f7fa;
		color: $color_40;
		border-color: $border-color_25;
		padding: 8px 20px;
		pointer-events: none;
		opacity: .65;
	}
}
.page-link {
	background: #f4f7fa;
	color: $color_40;
	border-color: $border-color_25;
	padding: 8px 20px;
	&:active {
		color: $color_13;
		font-weight: 500;
		box-shadow: none;
		background: #f7fafc;
	}
	&:focus {
		color: $color_13;
		font-weight: 500;
		box-shadow: none;
		background: #f7fafc;
	}
	&:hover {
		background: #f7fafc;
		color: $color_13;
	}
}
.page-item.active {
	.page-link {
		z-index: 3;
		color: $color_5;
		font-weight: 500;
		background-color: $background-color_10;
		border-color: $border-color_25;
	}
}
.page-link.active {
	color: $color_13;
	font-weight: 500;
	box-shadow: none;
	background: #f7fafc;
}
.page-item {
	&:first-child {
		.page-link {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}
	}
	&:last-child {
		.page-link {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}
}
.pagination-separated {
	.page-link {
		border-radius: 20px !important;
		margin-right: 5px;
	}
	.page-item {
		&:last-child {
			.page-link {
				margin-right: 0;
			}
		}
	}
}
.pagination-outline {
	.page-link {
		background: 0 0;
		&:hover {
			background: #f7fafc;
			color: $color_13;
		}
	}
}
.popover {
	border-radius: 10px;
	border-color: $border-color_25;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
}
.bs-popover-auto[data-popper-placement^=right] {
	>.popover-arrow {
		&::before {
			border-right-color: $border-right-color_2;
		}
	}
}
.bs-popover-end {
	>.popover-arrow {
		&::before {
			border-right-color: $border-right-color_2;
		}
	}
}
.bs-popover-auto[data-popper-placement^=left] {
	>.popover-arrow {
		&::before {
			border-left-color: $border-left-color_1;
		}
	}
}
.bs-popover-start {
	>.popover-arrow {
		&::before {
			border-left-color: $border-left-color_1;
		}
	}
}
.bs-popover-auto[data-popper-placement^=bottom] {
	>.popover-arrow {
		&::before {
			border-bottom-color: $border-bottom-color_3;
		}
	}
}
.bs-popover-bottom {
	>.popover-arrow {
		&::before {
			border-bottom-color: $border-bottom-color_3;
		}
	}
}
.bs-popover-auto[data-popper-placement^=top] {
	>.popover-arrow {
		&::before {
			border-top-color: $border-top-color_2;
		}
	}
}
.bs-popover-top {
	>.popover-arrow {
		&::before {
			border-top-color: $border-top-color_2;
		}
	}
}
.popover-header {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background: #f1f4f7;
	padding: 12px 20px;
	color: $color_34;
	border-color: $border-color_25;
}
.popover-body {
	padding: 12px 20px;
	color: $color_40;
	font-size: 14px;
}
.progress {
	border-radius: 5px;
}
.progress-bar {
	background-color: $background-color_2;
}
.toast {
	border-radius: 10px;
	border-color: $border-color_7;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
}
.toast-header {
	color: $color_13;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 12px 16px;
	border-color: $border-color_7;
}
.toast-body {
	padding: 12px 16px;
}
.accordion-item {
	border-color: $border-color_7;
	.accordion-button {
		padding: 14px 20px;
		box-shadow: none !important;
		border-color: $border-color_7;
		&:active {
			box-shadow: none !important;
			border-color: $border-color_7;
		}
		&:focus {
			box-shadow: none !important;
			border-color: $border-color_7;
		}
		&:not(.collapsed) {
			background-color: $background-color_41;
			font-weight: 500;
			color: $color_5;
			border-bottom: 1px solid #e1e7ed;
		}
		i {
			margin-right: 10px;
			filter: invert(34%) sepia(23%) saturate(3309%) hue-rotate(195deg) brightness(109%) contrast(115%);
		}
	}
	.accordion-body {
		padding: 14px 20px;
	}
	.accordion-button.collapsed {
		background-color: $background-color_8;
		&:hover {
			background: #f7fafc;
		}
		i {
			filter: invert(24%) sepia(38%) saturate(342%) hue-rotate(186deg) brightness(95%) contrast(88%);
		}
	}
	&:first-of-type {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		.accordion-button {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
	}
	&:last-of-type {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		.accordion-button.collapsed {
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}
}
.accordion-flush {
	.accordion-item {
		.accordion-button.collapsed {
			background: #fff;
		}
	}
}
.accordion-separated {
	.accordion-item {
		border-radius: 10px;
		.accordion-button.collapsed {
			border-radius: 10px;
		}
		.accordion-button {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		&:not(:last-of-type) {
			margin-bottom: 10px;
		}
	}
}
.blockUI.blockOverlay {
	border-radius: inherit;
	background: rgba(255,255,255) !important;
	opacity: .8 !important;
	z-index: 9999 !important;
}
.blockUI.blockMsg {
	background: 0 0 !important;
	width: 25px !important;
	border: 0 !important;
	height: 25px !important;
	left: calc(50% - 12.5px) !important;
	top: calc(50% - 12.5px) !important;
	z-index: 99999 !important;
}
.apexcharts-menu {
	display: block;
	visibility: hidden;
	transform: translateY(10px);
	opacity: 0 !important;
	border: none !important;
	border-radius: 10px !important;
	padding: 10px !important;
	min-width: 140px !important;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-transition: all .2s ease-in-out !important;
	-moz-transition: all .2s ease-in-out !important;
	-o-transition: all .2s ease-in-out !important;
	transition: all .2s ease-in-out !important;
	.apexcharts-menu-item {
		text-align: left !important;
		font-size: 13px;
		-webkit-transition: all .2s ease-in-out !important;
		-moz-transition: all .2s ease-in-out !important;
		-o-transition: all .2s ease-in-out !important;
		transition: all .2s ease-in-out !important;
		&:hover {
			background-color: $background-color_14 !important;
			color: $color_19;
		}
	}
}
.apexcharts-menu.apexcharts-menu-open {
	opacity: 1 !important;
	transform: none;
	visibility: visible;
}
.apexcharts-canvas {
	text {
		color: $color_40;
		font-weight: 500;
	}
}
.card {
	border: none;
	border-radius: 10px;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	.card-header {
		background: 0 0;
		border: none;
		padding: 25px 30px 10px;
		font-size: 14px;
		color: $color_13;
		margin: 0;
		font-weight: 600;
	}
	.card-title {
		font-size: 15px;
		color: $color_13;
		margin: 0;
		font-weight: 600;
		.badge {
			margin-left: 10px;
			font-size: 10px;
			vertical-align: middle;
			float: right;
		}
	}
	.card-body {
		.card-title {
			margin-bottom: 10px;
		}
		padding: 25px 30px;
	}
	.card-header+.card-body {
		padding-top: 7px;
	}
	.card-description {
		margin-bottom: 20px;
		i {
			vertical-align: text-bottom;
		}
	}
	>.list-group {
		&:last-child {
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
		}
		&:first-child {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
	}
}
.card-img {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}
.card-img-top {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.card-footer {
	border-color: $border-color_26;
	background: #f4f7fa;
	padding: 15px 30px;
	&:last-child {
		border-radius: 0 0 10px 10px;
	}
}
.card-img-overlay {
	padding: 25px 30px;
	border-radius: 10px;
	background: rgba(0,0,0,.25);
}
.card-img-bottom {
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}
.card-header-tabs {
	margin: -25px -30px 0;
	border-bottom: 1px solid #e1e7eb;
}
.card-header {
	a {
		font-weight: 500;
	}
}
.countdown-container {
	text-align: center;
	.countdown {
		clear: both;
		display: block;
		font-size: 24px;
		color: $color_41;
		margin: 20px 0 0;
		font-weight: 500;
		.countdown-block-container {
			display: inline-block;
			padding: 0 10px;
			position: relative;
			min-width: 100px;
			&:not(:last-child) {
				&::after {
					content: ':';
					display: inline-block;
					font-size: 24px;
					position: absolute;
					right: 0;
					top: 0;
					line-height: 40px;
				}
			}
			h4 {
				color: $color_46;
				font-size: 16px;
			}
		}
	}
}
.lightbox-example {
	a {
		img {
			max-width: 200px;
			margin-bottom: 10px;
			margin-right: 10px;
		}
	}
}
.list-group-item {
	border-color: $border-color_27;
	color: $color_40;
	padding: 12px 20px;
	&:first-child {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	&:last-child {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
}
.list-group-item.active {
	font-weight: 500;
	background: #dff0fe;
	color: $color_19;
	border-color: $border-color_27;
}
.list-group-flush {
	>.list-group-item {
		padding: 15px 30px;
	}
}
.list-group-item.list-group-item-primary {
	background: #dff0fe;
	color: $color_19;
}
.list-group-item.list-group-item-secondary {
	background: #fceace;
	color: $color_40;
}
.list-group-item.list-group-item-success {
	background: #e6fcf0;
	color: $color_7;
}
.list-group-item.list-group-item-danger {
	background: #fce3e5;
	color: $color_8;
}
.list-group-item.list-group-item-warning {
	background: #fff3e3;
	color: $color_9;
}
.list-group-item.list-group-item-info {
	background: #e0efff;
	color: $color_10;
}
.list-group-item.list-group-item-light {
	background: #f4f7fa;
	color: $color_40;
}
.list-group-item.list-group-item-dark {
	color: $color_17;
}
body.modal-open {
	overflow: visible;
}
.modal-content {
	border: none;
	border-radius: 10px;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
}
.modal-header {
	border-bottom: 0;
	padding: 25px 30px 10px;
	.btn-close {
		padding: 10px;
		margin: -10px -10px -10px auto;
	}
}
.modal-title {
	font-size: 16px;
	color: $color_13;
	margin: 0;
	font-weight: 600;
}
.modal-body {
	padding: 25px 30px;
}
.modal-footer {
	padding: 15px 30px;
	border: 0;
}
.nav-tabs {
	.nav-item.show {
		.nav-link {
			border-top: none;
			border-left: none;
			border-right: none;
			background: 0 0;
			color: $color_47;
			border-bottom: 2px solid #2269f4;
			opacity: 1;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}
	}
	.nav-link.active {
		border-top: none;
		border-left: none;
		border-right: none;
		background: 0 0;
		color: $color_47;
		border-bottom: 2px solid #2269f4;
		opacity: 1;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.nav-link {
		&:focus {
			border-top: none;
			border-left: none;
			border-right: none;
			opacity: 1;
		}
		&:hover {
			border-top: none;
			border-left: none;
			border-right: none;
			opacity: 1;
		}
		opacity: .5;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 2px solid transparent;
		font-weight: 500;
		padding: 12px 20px;
		-webkit-transition: all .2s ease-in-out !important;
		-moz-transition: all .2s ease-in-out !important;
		-o-transition: all .2s ease-in-out !important;
		transition: all .2s ease-in-out !important;
	}
}
.nav-pills {
	.nav-link {
		padding: 8px 16px;
		border-radius: 5px;
		opacity: .5;
		font-weight: 500;
		-webkit-transition: all .2s ease-in-out !important;
		-moz-transition: all .2s ease-in-out !important;
		-o-transition: all .2s ease-in-out !important;
		transition: all .2s ease-in-out !important;
		&:focus {
			opacity: 1;
		}
		&:hover {
			opacity: 1;
		}
	}
	.nav-link.active {
		opacity: 1;
		background: #dff0fe;
		color: $color_19;
	}
	.show {
		>.nav-link {
			opacity: 1;
			background: #dff0fe;
			color: $color_19;
		}
	}
}
.pricing-basic {
	.plan-title {
		font-size: 14px;
		text-transform: uppercase;
		color: $color_47;
		.badge {
			vertical-align: middle;
			float: right;
		}
	}
	.plan-price {
		margin-top: 20px;
		.plan-price-value {
			font-size: 42px;
			color: $color_34;
			font-weight: 700;
			line-height: 42px;
		}
		.plan-price-period {
			vertical-align: top;
			line-height: 42px;
			opacity: .5;
			margin-left: 10px;
		}
	}
	.plan-description {
		margin-top: 20px;
		display: block;
		opacity: .7;
	}
	.plan-list {
		list-style-type: none;
		padding-left: 0;
		margin-top: 15px;
		margin-bottom: 0;
		li {
			padding: 10px 10px 10px 36px;
			position: relative;
			color: $color_47;
			&::before {
				display: inline-block;
				content: '\e86c';
				font-family: $font-family_4;
				font-size: 22px;
				left: 0;
				position: absolute;
				line-height: 22px;
				filter: invert(24%) sepia(38%) saturate(342%) hue-rotate(186deg) brightness(95%) contrast(88%);
			}
		}
	}
}
.pricing-basic.pricing-selected {
	.plan-price-value {
		color: $color_19;
	}
	.plan-list {
		li {
			&::before {
				filter: invert(34%) sepia(23%) saturate(3309%) hue-rotate(195deg) brightness(109%) contrast(115%);
			}
		}
	}
}
.widget-list {
	.widget-list-content {
		margin: 0;
		.widget-list-item {
			padding: 12px 0;
			display: block;
			overflow: hidden;
			display: flex;
			align-items: center;
			.widget-list-item-check {
				vertical-align: middle;
				width: 30px;
				height: 45px;
				padding: 12.5px 5px 12.5px 0;
				.form-check {
					margin: 0;
					vertical-align: middle;
					padding-left: 21px;
					padding-top: 0;
					padding-bottom: 0;
					.form-check-input {
						height: 20px;
						width: 20px;
						margin-top: 0;
					}
				}
			}
			.widget-list-item-icon {
				width: 45px;
				height: 45px;
				background: #f4f7fa;
				border-radius: 10px;
				text-align: center;
				margin-right: 10px;
				vertical-align: middle;
				float: left;
				i {
					line-height: 45px;
					font-size: 22px;
				}
				.widget-list-item-icon-image {
					width: 100%;
					height: 100%;
					background-size: cover !important;
					border-radius: 10px;
					background-position: center center !important;
				}
			}
			.widget-list-item-avatar {
				width: 45px;
				height: 45px;
				margin-right: 10px;
				vertical-align: middle;
				float: left;
				.avatar {
					width: 45px;
					height: 45px;
					line-height: 45px;
				}
			}
			.widget-list-item-description {
				flex: 1;
				padding-left: 10px;
				overflow: hidden;
				.widget-list-item-description-title {
					display: block;
					font-weight: 500;
					white-space: nowrap;
					overflow: hidden !important;
					text-overflow: ellipsis;
					color: $color_33;
					text-decoration: none;
					-webkit-transition: all .2s ease-in-out !important;
					-moz-transition: all .2s ease-in-out !important;
					-o-transition: all .2s ease-in-out !important;
					transition: all .2s ease-in-out !important;
					&:hover {
						color: $color_30;
					}
				}
				.widget-list-item-description-subtitle {
					color: $color_16;
					white-space: nowrap;
					overflow: hidden !important;
					text-overflow: ellipsis;
				}
				.widget-list-item-description-progress {
					margin: 7px 0;
					display: block;
					.progress {
						height: 5px;
					}
				}
			}
			.widget-list-item-check+.widget-list-item-description {
				width: calc(100% - 45px);
			}
			.widget-list-item-avatar+.widget-list-item-description {
				width: calc(100% - 55px);
			}
			.widget-list-item-transaction-amount-negative {
				padding-left: 15px;
				font-size: 14px;
				font-weight: 600;
				color: $color_8;
			}
			.widget-list-item-transaction-amount-positive {
				padding-left: 15px;
				font-size: 14px;
				font-weight: 600;
				color: $color_7;
			}
			.widget-list-item-description-date {
				font-size: 13px;
				color: $color_16;
			}
			.widget-list-item-icon.widget-list-item-icon-large {
				width: 120px;
				height: 70px;
			}
			.widget-list-item-product-amount {
				font-size: 16px;
				font-weight: 700;
				color: $color_49;
			}
		}
		.widget-list-item.widget-list-item-green {
			.widget-list-item-icon {
				background: #e6fcf0;
				color: $color_7;
			}
		}
		.widget-list-item.widget-list-item-blue {
			.widget-list-item-icon {
				background: #dff0fe;
				color: $color_19;
			}
		}
		.widget-list-item.widget-list-item-red {
			.widget-list-item-icon {
				background: #fce3e5;
				color: $color_8;
			}
		}
		.widget-list-item.widget-list-item-yellow {
			.widget-list-item-icon {
				background: #fff3e3;
				color: $color_9;
			}
		}
		.widget-list-item.widget-list-item-purple {
			.widget-list-item-icon {
				background: #e3e3ff;
				color: $color_48;
			}
		}
		.widget-list-item-blog-post {
			.widget-list-item-description-content {
				display: -webkit-box;
				max-width: 100%;
				margin: 0 auto;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				color: $color_16;
			}
		}
	}
}
.widget-newsletter {
	h5 {
		text-align: center;
		font-size: 15px;
		display: block;
	}
	p {
		margin: 8px 0;
		display: block;
		text-align: center;
		font-size: 13px;
		opacity: .7;
	}
}
.widget-files {
	.widget-files-list {
		padding-left: 0;
		margin: 0;
		.widget-files-list-item {
			padding: 10px 10px;
			border-radius: 10px;
			background: #f4f7fa;
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
			.widget-files-list-item-icon {
				display: block;
				width: 45px;
				height: 45px;
				text-align: center;
				margin-right: 10px;
				i {
					line-height: 45px;
					font-size: 18px;
					color: $color_30;
				}
			}
			.widget-files-list-item-content {
				flex: 1;
				.widget-files-list-item-title {
					display: block;
					font-size: 13px;
					font-weight: 500;
				}
				.widget-files-list-item-size {
					font-size: 13px;
					opacity: .7;
				}
			}
			.widget-files-list-item-download-btn {
				margin: 0 10px;
				i {
					font-size: 18px;
				}
			}
		}
	}
}
.widget-info {
	.widget-info-container {
		text-align: center;
		.widget-info-image {
			width: 100%;
			height: 150px;
			background-size: 150px !important;
			background-position: center center !important;
			background-repeat: no-repeat !important;
		}
		.widget-info-title {
			margin: 20px 0;
			color: $color_47;
			font-weight: 600;
		}
		.widget-info-text {
			text-align: center;
			opacity: .7;
		}
		.widget-info-action {
			margin: 20px 0 10px;
			display: inline-block;
		}
	}
}
.widget-info-inline {
	.widget-info-container {
		.widget-info-image {
			width: 60%;
			height: 60%;
			position: absolute;
			right: 20px;
			bottom: 20px;
			background-size: contain !important;
			background-repeat: no-repeat !important;
			background-position: right bottom !important;
			z-index: 1;
		}
		.widget-info-text {
			max-width: 50%;
			opacity: .7;
		}
	}
}
.widget-popular-product {
	.widget-popular-product-container {
		.widget-popular-product-image {
			width: 100%;
			position: relative;
			overflow: hidden;
			border-radius: 10px;
			img {
				width: 100%;
			}
		}
		.widget-popular-product-tags {
			padding: 10px 0;
		}
		.widget-popular-product-content {
			.widget-popular-product-title {
				padding: 5px 0 6px;
				font-size: 18px;
				color: $color_47;
				text-decoration: none;
				font-weight: 700;
				display: block;
				-webkit-transition: all .2s ease-in-out !important;
				-moz-transition: all .2s ease-in-out !important;
				-o-transition: all .2s ease-in-out !important;
				transition: all .2s ease-in-out !important;
				&:hover {
					opacity: .6;
				}
			}
			.widget-popular-product-text {
				color: $color_16;
			}
			.widget-popular-product-rating {
				i {
					font-size: 18px;
					color: $color_50;
					vertical-align: middle;
				}
				.widget-popular-product-rating-num {
					vertical-align: middle;
					margin-left: 5px;
					color: $color_16;
				}
			}
		}
	}
}
.widget-popular-blog {
	.widget-popular-blog-container {
		display: flex;
		.widget-popular-blog-image {
			width: 120px;
			border-radius: 10px;
			img {
				width: 100%;
				border-radius: 10px;
			}
		}
		.widget-popular-blog-content {
			flex: 1;
			overflow: hidden;
		}
		.widget-popular-blog-text {
			display: -webkit-box;
			max-width: 100%;
			margin: 0 auto;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 4;
			color: $color_16;
		}
		.widget-popular-blog-title {
			display: -webkit-box;
			max-width: 100%;
			margin: 0 auto;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: 500;
			font-size: 15px;
			margin-bottom: 5px;
			-webkit-line-clamp: 2;
		}
	}
	.widget-popular-blog-date {
		color: $color_16;
		line-height: 35px;
	}
}
.widget-action-list {
	.widget-action-list-container {
		.widget-action-list-item {
			flex: 1;
			text-align: center;
			a {
				display: inline-block;
				align-self: center;
				text-align: center;
				text-decoration: none;
				padding: 9px 0;
				.widget-action-list-item-icon {
					display: block;
					width: 80px;
					height: 80px;
					border-radius: 40px;
					background: #f4f7fa;
					border: 1px solid #e3e6ea;
					-webkit-transition: all .2s ease-in-out !important;
					-moz-transition: all .2s ease-in-out !important;
					-o-transition: all .2s ease-in-out !important;
					transition: all .2s ease-in-out !important;
					i {
						line-height: 80px;
						font-size: 28px;
						color: $color_51;
					}
				}
				.widget-action-list-item-title {
					display: block;
					color: $color_40;
					font-weight: 500;
					margin-top: 15px;
					-webkit-transition: all .2s ease-in-out !important;
					-moz-transition: all .2s ease-in-out !important;
					-o-transition: all .2s ease-in-out !important;
					transition: all .2s ease-in-out !important;
				}
				&:hover {
					.widget-action-list-item-icon {
						box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
					}
					.widget-action-list-item-title {
						color: $color_52;
					}
				}
			}
		}
	}
}
.widget-tweet {
	background: #1fa1f0;
	overflow: hidden;
	&:after {
		content: "";
		position: absolute;
		right: 20px;
		height: 150px;
		width: 150px;
		z-index: 1;
		bottom: -40px;
		background-size: cover;
		opacity: .15;
		filter: invert(99%) sepia(77%) saturate(97%) hue-rotate( 281deg) brightness(115%) contrast(100%);
	}
	.widget-tweet-container {
		.widget-tweet-content {
			.widget-tweet-text {
				color: $color_23;
				max-width: 85%;
				font-size: 21px;
				margin-bottom: 0;
			}
			.widget-tweet-author {
				color: $color_23;
				margin-top: 10px;
				font-size: 16px;
				opacity: .7;
				margin-bottom: 0;
			}
		}
	}
}
.widget-connection-request {
	.widget-connection-request-container {
		margin-bottom: 15px;
		.widget-connection-request-avatar {
			margin-right: 10px;
		}
		.widget-connection-request-info {
			.widget-connection-request-info-name {
				display: block;
				font-weight: 500;
				font-size: 15px;
				line-height: 30px;
			}
			.widget-connection-request-info-about {
				display: block;
				font-size: 13px;
				color: $color_16;
			}
			.widget-connection-request-info-count {
				display: block;
				font-size: 13px;
				color: $color_16;
			}
		}
	}
}
.widget-bank-card {
	height: 213px;
	.card-body {
		padding: 0;
	}
	.widget-bank-card-container {
		height: 100%;
		width: 100%;
		padding: 25px 30px;
		border-radius: 10px;
		.widget-bank-card-logo {
			position: absolute;
			right: 30px;
			top: 25px;
		}
		.widget-bank-card-balance-title {
			display: block;
			font-size: 14px;
			font-weight: 500;
			opacity: .7;
		}
		.widget-bank-card-balance {
			font-size: 30px;
			font-weight: 600;
			letter-spacing: 2px;
		}
		.widget-bank-card-number {
			font-size: 18px;
			font-weight: 500;
			line-height: 30px;
			word-spacing: 8px;
		}
	}
	.widget-bank-card-visa {
		background: #21336e;
		&::after {
			position: absolute;
			bottom: 5px;
			right: 30px;
			content: '';
			display: block;
			height: 80px;
			width: 80px;
			background-size: 80px 80px;
			background-position: bottom right;
			filter: invert(41%) sepia(6%) saturate(4020%) hue-rotate(189deg) brightness(87%) contrast(90%);
		}
		.widget-bank-card-logo {
			filter: invert(41%) sepia(6%) saturate(4020%) hue-rotate(189deg) brightness(87%) contrast(90%);
			background-size: contain;
			width: 80px;
			height: 50px;
		}
		.widget-bank-card-balance {
			color: $color_23;
		}
		.widget-bank-card-balance-title {
			color: $color_23;
		}
		.widget-bank-card-number {
			color: $color_23;
		}
	}
	.widget-bank-card-mastercard {
		&::after {
			position: absolute;
			bottom: 20px;
			right: 30px;
			content: '';
			display: block;
			height: 50px;
			width: 80px;
			background-size: 80px 50px;
			background-position: bottom right;
		}
		.widget-bank-card-logo {
			background-size: contain;
			width: 150px;
			height: 20px;
		}
	}
}
.widget-stats {
	.widget-stats-container {
		.widget-stats-icon {
			width: 60px;
			height: 60px;
			display: block;
			text-align: center;
			margin-right: 20px;
			background: #f4f7fa;
			border-radius: 10px;
			i {
				line-height: 60px;
				font-size: 28px;
			}
		}
		.widget-stats-icon.widget-stats-icon-primary {
			background: #dff0fe;
			color: $color_19;
		}
		.widget-stats-icon.widget-stats-icon-warning {
			background: #fff3e3;
			color: $color_9;
		}
		.widget-stats-icon.widget-stats-icon-danger {
			background: #fce3e5;
			color: $color_8;
		}
		.widget-stats-icon.widget-stats-icon-success {
			background: #e6fcf0;
			color: $color_7;
		}
		.widget-stats-icon.widget-stats-icon-purple {
			background: #edeafd;
			color: $color_53;
		}
		.widget-stats-content {
			.widget-stats-amount {
				display: block;
				font-size: 28px;
				font-weight: 700;
				letter-spacing: 2px;
				color: $color_47;
			}
			.widget-stats-info {
				display: block;
				color: $color_54;
				font-size: 13px;
			}
			.widget-stats-title {
				display: block;
				text-transform: uppercase;
				font-weight: 500;
				color: $color_54;
				font-size: 13px;
			}
		}
		.widget-stats-indicator {
			padding: 5px 9px;
			border-radius: 5px;
			font-weight: 500;
			font-size: 12px;
			background: #f4f7fa;
			color: $color_12;
			i {
				vertical-align: text-bottom;
				font-size: 18px;
			}
		}
		.widget-stats-indicator.widget-stats-indicator-positive {
			background: #d6f5e3;
			color: $color_7;
		}
		.widget-stats-indicator.widget-stats-indicator-negative {
			background: #fce3e5;
			color: $color_8;
		}
	}
	.widget-stats-progress {
		padding-top: 15px;
		.progress {
			height: 5px;
			margin-bottom: 0;
		}
	}
	.widget-stats-chart {
		margin: 10px -30px -25px;
	}
}
.widget-stats-large {
	.widget-stats-large-chart-container {
		border-right: 1px solid #e2e6e9;
		margin: 0 -10.5px 0 -1px;
	}
	.widget-stats-large-info-container {
		background: #f4f7fa;
		margin: 0 -1px 0 -10.5px;
		border-radius: 0 10px 10px 0;
		height: 100%;
	}
}
.widget-info-navigation {
	.widget-info-navigation-container {
		display: flex;
		align-items: center;
		.widget-info-navigation-content {
			flex: 1;
		}
	}
}
.widget-payment-request {
	.widget-payment-request-container {
		.widget-payment-request-author {
			display: flex;
			align-items: center;
			background: #f4f7fa;
			padding: 10px 30px;
			margin: 0 -30px;
			border-top: 1px solid #e2e6e9;
			.widget-payment-request-author-info {
				flex: 1;
				align-items: center;
			}
			.widget-payment-request-author-name {
				display: block;
				font-weight: 500;
			}
			.widget-payment-request-author-about {
				color: $color_16;
			}
		}
		.widget-payment-request-product {
			display: flex;
			align-items: center;
			background: #f4f7fa;
			padding: 10px 30px;
			margin: 0 -30px;
			border-top: 1px solid #e2e6e9;
			border-bottom: 1px solid #e2e6e9;
			.widget-payment-request-product-info {
				flex: 1;
				align-items: center;
			}
			.widget-payment-request-product-name {
				display: block;
				font-weight: 500;
			}
			.widget-payment-request-product-about {
				color: $color_16;
			}
			.widget-payment-request-product-image {
				position: relative;
				width: 60px;
				height: 60px;
				img {
					width: 100%;
				}
			}
			.widget-payment-request-product-price {
				font-size: 17px;
				color: $color_7;
				font-weight: 700;
			}
			.widget-payment-request-product-info-content {
				flex: 1;
			}
		}
		.widget-payment-request-info {
			.widget-payment-request-info-title {
				font-weight: 500;
				color: $color_34;
				font-size: 13px;
				text-transform: uppercase;
			}
			.widget-payment-request-info-item {
				&:not(:last-child) {
					padding-bottom: 13px;
					border-bottom: 1px solid #f4f7fa;
					margin-bottom: 13px;
				}
			}
		}
		.widget-payment-request-actions {
			padding-top: 20px;
			border-top: 1px solid #f4f7fa;
		}
	}
}
.widget-chat {
	.widget-chat-active-user {
		&::after {
			width: 8px;
			height: 8px;
			border-radius: 4px;
			margin-left: 10px;
			vertical-align: middle;
			display: inline-block;
			content: '';
			background: #4bad48;
		}
	}
	.widget-chat-inactive-user {
		&::after {
			width: 8px;
			height: 8px;
			border-radius: 4px;
			margin-left: 10px;
			vertical-align: middle;
			display: inline-block;
			content: '';
			background: #ff4857;
		}
	}
	.widget-chat-messages-container {
		margin-top: 7px;
		position: relative;
		padding: 20px 30px;
		border-top: 1px solid #e2e6e9;
		background: #f4f7fa;
		max-height: 395px;
		display: flex;
		flex-direction: column-reverse;
		overflow-y: auto;
		.widget-chat-message-item {
			display: flex;
			align-items: end;
			margin-bottom: 20px;
			img {
				width: 40px;
				height: 40px;
				border-radius: 25px;
				margin-right: 20px;
			}
			.widget-chat-message-item-text {
				border-radius: 10px;
				padding: 15px 20px;
				background: #fff;
				box-shadow: 0 0 11px 1px rgb(0 0 0 / 5%);
				width: auto;
				display: table;
				margin-right: 30px;
				&:not(:last-child) {
					margin-bottom: 7px;
				}
			}
		}
		.widget-chat-message-item.own {
			.widget-chat-message-item-text {
				background: #037afb;
				color: $color_23;
			}
		}
	}
	.widget-chat-compose {
		height: 70px;
		border-top: 1px solid #e2e6e9;
		position: relative;
		display: flex;
		align-items: center;
		.widget-chat-compose-input {
			border: none;
			height: 100%;
			padding: 0 30px;
			flex: 1;
			border-radius: 10px;
			color: $color_51;
			&::placeholder {
				opacity: .5;
			}
			&:focus {
				box-shadow: none;
				border: none;
				outline: 0;
			}
		}
		.widget-chat-compose-send-btn {
			background: 0 0;
			border: none;
			box-shadow: none;
			text-align: center;
			padding: 0;
			margin: 0 15px;
			height: 40px;
			width: 40px;
			display: block;
			i {
				font-size: 17px;
				line-height: 40px;
				color: $color_30;
			}
		}
	}
}
.form-label {
	margin-bottom: 8px;
	font-size: 13px;
	font-weight: 500;
}
.form-control {
	border-radius: 8px;
	padding: 10px 18px;
	border-color: $border-color_26;
	color: $color_1;
	-webkit-transition: border .2s ease-in-out,background-color .2s ease-in-out;
	-moz-transition: border .2s ease-in-out,background-color .2s ease-in-out;
	-o-transition: border .2s ease-in-out,background-color .2s ease-in-out;
	transition: border .2s ease-in-out,background-color .2s ease-in-out;
	&::-webkit-input-placeholder {
		color: $color_4;
	}
	&:focus {
		border-color: $border-color_8;
		box-shadow: none;
	}
	&:disabled {
		background-color: $background-color_42;
	}
}
.form-control-sm {
	padding: 8px 14px;
	font-size: 12px;
}
.form-control-lg {
	padding: 12px 20px;
	font-size: 16px;
}
.form-text {
	margin-top: 4px;
	font-size: 12px;
	color: $color_4;
}
.form-control.light-focus {
	&:focus {
		border-color: $border-color_28;
	}
}
.form-control.form-control-solid {
	border: none;
	background: #f4f7fa;
	&:focus {
		background: #ebf0f5;
	}
}
.form-control.form-control-solid-bordered {
	background: #f4f7fa;
}
.form-control.form-control-material {
	border-radius: 0;
	border-width: 0 0 1px;
}
.form-control.form-control-transparent {
	border-width: 0;
}
.form-control.form-control-rounded {
	border-radius: 20px;
}
.form-control[readonly] {
	background-color: $background-color_42;
}
.form-floating {
	>label {
		padding: 19px 18px;
	}
	>.form-control {
		height: 60px;
		padding: 14px 18px;
	}
	>.form-select {
		height: 60px;
		padding: 14px 18px;
	}
}
.form-check {
	min-height: 24px;
	padding-left: 28px;
	margin-bottom: 4px;
}
.form-check-input {
	width: 16px;
	height: 16px;
	border-color: $border-color_26;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	&:focus {
		box-shadow: none;
		border-color: $border-color_29;
	}
	&:checked {
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
}
.form-check-label {
	margin-left: 5px;
}
.input-group-text {
	padding: 10px 14px;
	font-size: 13px;
	font-weight: 500;
	color: $color_1;
	background-color: $background-color_8;
	border: 1px solid #e3e6ea;
	border-radius: 8px;
	position: relative;
}
.input-group-text.input-group-text-solid {
	border: none;
	background: #ebf0f5;
}
.input-group-text.input-group-text-material {
	border-radius: 0;
	border-width: 0 0 1px;
	background: 0 0;
}
.input-group-text.input-group-text-transparent {
	border: none;
	background-color: $background-color_14;
}
.form-range {
	&:focus {
		&::-webkit-slider-thumb {
			box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(34,105,245,.25);
		}
		&::-moz-range-thumb {
			box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(34,105,245,.25);
		}
		&::-ms-thumb {
			box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(34,105,245,.25);
		}
	}
	&::-webkit-slider-thumb {
		background-color: $background-color_26;
		&:active {
			background-color: $background-color_43;
		}
	}
	&::-moz-range-thumb {
		background-color: $background-color_26;
		&:active {
			background-color: $background-color_43;
		}
	}
	&::-ms-thumb {
		background-color: $background-color_26;
		&:active {
			background-color: $background-color_43;
		}
	}
	&::-webkit-slider-runnable-track {
		background-color: $background-color_44;
	}
}
.form-select {
	border-radius: 8px;
	padding: 10px 36px 10px 18px;
	border-color: $border-color_26;
	color: $color_1;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	&:focus {
		border-color: $border-color_8;
		box-shadow: none;
	}
}
.form-control.is-valid {
	border-color: $border-color_4;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%234bad48'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
	&:focus {
		border-color: $border-color_4;
		box-shadow: none;
	}
}
.was-validated {
	.form-control {
		&:valid {
			border-color: $border-color_4;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%234bad48'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
			&:focus {
				border-color: $border-color_4;
				box-shadow: none;
			}
		}
		&:invalid {
			border-color: $border-color_9;
			background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FF4857'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E%0A");
			&:focus {
				border-color: $border-color_9;
				box-shadow: none;
			}
		}
	}
	.form-select {
		&:valid {
			border-color: $border-color_4;
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%234bad48'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
			background-position: right .75rem center,center right 2.25rem;
			background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
			&:focus {
				border-color: $border-color_4;
				box-shadow: none;
			}
		}
		&:invalid {
			border-color: $border-color_9;
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FF4857'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E%0A");
			background-position: right .75rem center,center right 2.25rem;
			background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
			&:focus {
				border-color: $border-color_9;
				box-shadow: none;
			}
		}
	}
	.form-check-input {
		&:valid {
			&:checked {
				background-color: $background-color_4;
				box-shadow: none;
				border-color: $border-color_4;
			}
			&:focus {
				box-shadow: none;
				border-color: $border-color_4;
			}
		}
		&:invalid {
			&:checked {
				box-shadow: none;
				border-color: $border-color_9;
			}
			&:focus {
				box-shadow: none;
				border-color: $border-color_9;
			}
			border-color: $border-color_9;
		}
		&:valid~.form-check-label {
			color: $color_7;
		}
		&:invalid~.form-check-label {
			color: $color_8;
		}
	}
}
.form-select.is-valid {
	border-color: $border-color_4;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%234bad48'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
	background-position: right .75rem center,center right 2.25rem;
	background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
	&:focus {
		border-color: $border-color_4;
		box-shadow: none;
	}
}
.valid-feedback {
	color: $color_7;
	font-size: 12px;
}
.form-control.is-invalid {
	border-color: $border-color_9;
	background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FF4857'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E%0A");
	&:focus {
		border-color: $border-color_9;
		box-shadow: none;
	}
}
.form-select.is-invalid {
	border-color: $border-color_9;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FF4857'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E%0A");
	background-position: right .75rem center,center right 2.25rem;
	background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
	&:focus {
		border-color: $border-color_9;
		box-shadow: none;
	}
}
.invalid-feedback {
	color: $color_8;
	font-size: 12px;
}
.form-check-input.is-valid {
	&:checked {
		background-color: $background-color_4;
		box-shadow: none;
		border-color: $border-color_4;
	}
	&:focus {
		box-shadow: none;
		border-color: $border-color_4;
	}
}
.form-check-input.is-invalid {
	&:checked {
		box-shadow: none;
		border-color: $border-color_9;
	}
	&:focus {
		box-shadow: none;
		border-color: $border-color_9;
	}
	border-color: $border-color_9;
}
.form-check-input.is-valid~.form-check-label {
	color: $color_7;
}
.form-check-input.is-invalid~.form-check-label {
	color: $color_8;
}
.valid-tooltip {
	background-color: $background-color_45;
}
.invalid-tooltip {
	background-color: $background-color_46;
}
.dropzone {
	border-radius: 10px;
	background: #f4f7fa;
	border: 2px solid #e3e6ea;
	.dz-preview {
		background-color: $background-color_14;
		.dz-image {
			border-radius: 10px;
		}
		.dz-details {
			border-radius: 10px;
			background: #e3e6ea;
			bottom: 0;
		}
		.dz-error-message {
			background: #ff4857;
			&:after {
				border-bottom-color: $border-bottom-color_4;
			}
		}
	}
}
.note-editor {
	border-radius: 10px;
	.note-toolbar {
		padding: 15px 20px;
		border-bottom: 1px solid #e2e6e9;
		background: #f4f7fa;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		.note-dropdown-menu {
			min-width: 200px;
		}
		.note-color-all {
			.note-dropdown-menu {
				min-width: 355px;
			}
		}
	}
}
.note-editor.note-airframe {
	border-color: $border-color_30;
	.note-statusbar {
		border-color: $border-color_30;
		background: #f4f7fa;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.note-editing-area {
		.note-editable {
			padding: 15px 20px;
		}
	}
	.note-placeholder {
		padding: 15px 20px;
	}
}
.note-editor.note-frame {
	border-color: $border-color_30;
	.note-statusbar {
		border-color: $border-color_30;
		background: #f4f7fa;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.note-editing-area {
		.note-editable {
			padding: 15px 20px;
		}
	}
	.note-placeholder {
		padding: 15px 20px;
	}
}
[class*=" note-icon"] {
	&:before {
		color: $color_40;
	}
}
[class^=note-icon] {
	&:before {
		color: $color_40;
	}
}
.note-btn {
	display: inline-block;
	padding: 6px 20px;
	border-radius: 5px;
	font-size: 13px;
	height: 33px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: all .2s ease-in-out !important;
	-moz-transition: all .2s ease-in-out !important;
	-o-transition: all .2s ease-in-out !important;
	transition: all .2s ease-in-out !important;
	&:focus {
		border-radius: 5px;
		box-shadow: none;
	}
	&:hover {
		border-radius: 5px;
		box-shadow: none;
	}
	&::after {
		display: none;
	}
}
.note-dropdown-menu {
	display: block;
	visibility: hidden;
	margin-top: 40px;
	opacity: 0;
	border: none;
	border-radius: 10px;
	padding: 10px;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	min-width: 345px;
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-transition: all .2s ease-in-out !important;
	-moz-transition: all .2s ease-in-out !important;
	-o-transition: all .2s ease-in-out !important;
	transition: all .2s ease-in-out !important;
	&:after {
		position: absolute;
		top: -7px;
		left: 10px;
		right: auto;
		display: inline-block !important;
		border-right: 7px solid transparent;
		border-bottom: 7px solid #fff;
		border-left: 7px solid transparent;
		content: '';
	}
	.note-dropdown-item {
		color: $color_17;
		padding: 5px 10px;
		margin: 5px 0;
		display: block;
		position: relative;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		&:hover {
			background: 0 0;
			font-weight: 500;
		}
	}
	.note-dropdown-item.active {
		color: $color_34;
		font-weight: 500;
		background-color: $background-color_14;
	}
	.note-btn {
		padding: 6px 10px;
		font-size: 12px;
	}
}
.note-btn-group.open {
	.note-dropdown-menu {
		opacity: 1;
		transform: none;
		visibility: visible;
		top: 20px !important;
	}
}
.note-popover {
	.popover-content {
		.note-dropdown-menu {
			min-width: 200px;
		}
		.note-color-all {
			.note-dropdown-menu {
				min-width: 355px;
			}
		}
	}
}
.note-icon-caret {
	margin: 0 -10px 0 10px;
	line-height: 18px;
	color: $color_17;
	&::before {
		content: '\e5cf';
		font-family: $font-family_3;
		font-size: 16px;
	}
}
.note-modal-content {
	border: none;
	border-radius: 10px;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
}
.note-modal-header {
	border: 0;
	padding: 25px 30px 10px;
	.close {
		padding: 10px;
		margin: -10px -10px -10px auto;
	}
}
.note-modal-title {
	font-size: 16px;
	color: $color_13;
	margin: 0;
	font-weight: 600;
}
.note-modal-body {
	padding: 25px 30px;
	kbd {
		border-radius: 5px;
		padding: 4px 7px;
		font-weight: 400;
		font-size: 11px;
		background-color: $background-color_18;
	}
}
.note-modal-footer {
	padding: 15px 30px;
	border: 0;
	height: auto;
	a {
		color: $color_2;
	}
}
.note-frame {
	color: $color_1;
	background: #fff;
}
.flatpickr-calendar {
	width: 340px;
	margin-top: 10px;
	padding: 15px;
	border-radius: 10px;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	&:before {
		display: none !important;
	}
	&:after {
		position: absolute;
		top: -10px;
		left: 20px;
		right: auto;
		display: inline-block !important;
		border-right: 7px solid transparent;
		border-bottom: 7px solid #fff;
		border-left: 7px solid transparent;
		content: '';
	}
}
.flatpickr-input {
	background-color: $background-color_10 !important;
}
.form-control.form-control-solid.flatpickr-input {
	background-color: $background-color_8;
}
.flatpickr-months {
	.flatpickr-next-month {
		top: 15px;
		&:hover {
			svg {
				filter: invert(42%) sepia(56%) saturate(7488%) hue-rotate(214deg) brightness(98%) contrast(95%);
			}
		}
	}
	.flatpickr-prev-month {
		top: 15px;
		&:hover {
			svg {
				filter: invert(42%) sepia(56%) saturate(7488%) hue-rotate(214deg) brightness(98%) contrast(95%);
			}
		}
	}
	.flatpickr-next-month.flatpickr-prev-month {
		left: 15px;
	}
	.flatpickr-prev-month.flatpickr-prev-month {
		left: 15px;
	}
	.flatpickr-next-month.flatpickr-next-month {
		right: 15px;
	}
	.flatpickr-prev-month.flatpickr-next-month {
		right: 15px;
	}
	margin-bottom: 15px;
	.flatpickr-month {
		height: 44px;
	}
}
.flatpickr-current-month {
	font-size: 14px;
	height: 44px;
	padding: 0;
	.flatpickr-monthDropdown-months {
		border-radius: 8px;
		padding: 10px 20px 10px 18px;
		border-color: $border-color_26;
		max-width: 100px;
	}
	.numInputWrapper {
		width: 80px;
		border-radius: 10px;
		margin-left: 10px;
	}
	input.cur-year {
		border-radius: 8px;
		padding: 10px 0 10px 18px;
		border-color: $border-color_26;
	}
}
.numInputWrapper {
	span.arrowUp {
		background: 0 0;
		border: none;
		height: 10px;
		top: 10px;
		right: 10px;
	}
	span.arrowDown {
		background: 0 0;
		border: none;
		height: 10px;
		top: 20px;
		right: 10px;
	}
}
span.flatpickr-weekday {
	color: $color_1;
}
.flatpickr-day {
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.flatpickr-day.today {
	border-color: $border-color_14;
	background-color: $background-color_3;
}
.flatpickr-day.endRange {
	background-color: $background-color_26;
	border-color: $border-color_8;
	&:focus {
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	&:hover {
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
}
.flatpickr-day.endRange.inRange {
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.flatpickr-day.endRange.nextMonthDay {
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.flatpickr-day.endRange.prevMonthDay {
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.flatpickr-day.selected {
	background-color: $background-color_26;
	border-color: $border-color_8;
	&:focus {
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	&:hover {
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
}
.flatpickr-day.selected.inRange {
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.flatpickr-day.selected.nextMonthDay {
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.flatpickr-day.selected.prevMonthDay {
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.flatpickr-day.startRange {
	background-color: $background-color_26;
	border-color: $border-color_8;
	&:focus {
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
	&:hover {
		background-color: $background-color_26;
		border-color: $border-color_8;
	}
}
.flatpickr-day.startRange.inRange {
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.flatpickr-day.startRange.nextMonthDay {
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.flatpickr-day.startRange.prevMonthDay {
	background-color: $background-color_26;
	border-color: $border-color_8;
}
.select2-selection {
	height: auto !important;
	box-shadow: none;
	border-radius: 8px !important;
	padding: 10px 36px 10px 18px;
	border-color: $border-color_26 !important;
	color: $color_1;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	.select2-selection__rendered {
		line-height: 1.5 !important;
		padding-left: 0 !important;
		font-size: 14px;
		font-weight: 400;
		color: $color_1;
	}
	.select2-selection__arrow {
		height: 41px !important;
		right: 20px !important;
	}
}
.select2-container--open {
	.select2-selection {
		border-color: $border-color_28 !important;
	}
}
.select2-selection.select2-selection--multiple {
	padding: 9px 25px !important;
	min-height: 0 !important;
	.select2-selection__rendered {
		margin-top: 3px;
		margin-bottom: -3px;
		input {
			margin-top: 0 !important;
		}
	}
}
.select2-container--default {
	.select2-selection--single {
		.select2-selection__arrow {
			b {
				width: 0;
				height: 0;
				border: none !important;
				margin: 0;
				&::before {
					display: block;
					font-family: $font-family_3;
					content: "\e5cf";
					width: 10px;
					height: 10px;
					position: absolute;
					top: -15px;
					font-size: 18px;
				}
			}
		}
	}
	.select2-results__option {
		.select2-results__option {
			color: $color_55;
			padding: 5px 10px;
			margin: 5px 0;
			display: block;
			font-size: 13px;
			font-weight: 400;
			position: relative;
			border-radius: 5px;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
		}
	}
	.select2-results__option--highlighted[aria-selected] {
		background: 0 0;
		color: $color_56;
	}
	.select2-results__option[aria-selected=true] {
		&:hover {
			color: $color_19;
		}
		border: 1px solid #dff0fe !important;
		background: #dff0fe;
		color: $color_19;
	}
	.select2-results__group {
		padding: 6px;
		font-size: 11px;
	}
}
.select2-container--default.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			b {
				width: 0;
				height: 0;
				border: none !important;
				margin: 0;
				&::before {
					display: block;
					font-family: $font-family_3;
					content: "\e5ce";
					width: 10px;
					height: 10px;
					position: absolute;
					top: -15px;
					font-size: 18px;
				}
			}
		}
	}
}
.select2-search--dropdown {
	.select2-search__field {
		border-radius: 8px;
		padding: 10px 18px;
		border-color: $border-color_26 !important;
		color: $color_1;
		-webkit-transition: border .2s ease-in-out,background-color .2s ease-in-out;
		-moz-transition: border .2s ease-in-out,background-color .2s ease-in-out;
		-o-transition: border .2s ease-in-out,background-color .2s ease-in-out;
		transition: border .2s ease-in-out,background-color .2s ease-in-out;
		&:active {
			box-shadow: none !important;
			border-color: $border-color_8 !important;
			outline: 0;
		}
		&:focus {
			box-shadow: none !important;
			border-color: $border-color_8 !important;
			outline: 0;
		}
	}
}
.select2-dropdown {
	padding: 10px 15px !important;
	border: none !important;
	margin-top: 3px !important;
	border-radius: 10px !important;
	box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
	-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
}
.select2-dropdown--above {
	margin-top: -3px !important;
	border-bottom: 1px solid #e6e6e6 !important;
}
.select2-selection__choice {
	margin-top: 0 !important;
	height: 19px !important;
	border: 1px solid #dff0fe !important;
	background: #dff0fe !important;
	color: $color_19 !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	padding: 0 10px;
	span {
		color: $color_19 !important;
	}
}
.select2-results__option {
	border-radius: 20px;
	padding: 9px 16px;
}
.mailbox-container {
	>.card {
		overflow: hidden;
		>.container-fluid {
			height: 100%;
			margin: 0;
			>.row {
				height: 100%;
				margin: 0;
			}
		}
	}
	.mailbox-compose-btn {
		position: fixed;
		right: 70px;
		bottom: 70px;
	}
	.mailbox-list {
		height: 100%;
		background: #f4f7fa;
		border-right: 1px solid #e3e6ea;
		margin: 0;
		padding: 0;
		overflow: auto;
		ul {
			margin: 0;
		}
		.mailbox-list-item {
			a {
				display: flex;
				align-items: center;
				text-decoration: none;
				padding: 10px;
				border-bottom: 1px solid #e3e6ea;
				img {
					height: 50px;
					width: 50px;
					border-radius: 25px;
				}
			}
			.mailbox-list-item-content {
				padding: 10px 15px;
				.mailbox-list-item-title {
					color: $color_56;
					font-weight: 500;
				}
				.mailbox-list-item-text {
					font-size: 13px;
					color: $color_57;
					margin-bottom: 0;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
					display: -webkit-box;
				}
			}
		}
		.mailbox-list-item.active {
			a {
				background: #eaeff5;
			}
		}
	}
}
.mailbox-open-content {
	margin: 0;
	padding: 30px;
	overflow: auto;
	height: 100%;
	.mailbox-open-title {
		font-size: 21px;
		font-weight: 400;
		color: $color_58;
	}
	.mailbox-open-date {
		font-size: 13px;
		margin-bottom: 10px;
		display: block;
		color: $color_59;
	}
	.mailbox-open-author {
		display: flex;
		margin-top: 20px;
		align-items: center;
		border-bottom: 1px solid #f1f1f1;
		padding-bottom: 20px;
		img {
			width: 50px;
			height: 50px;
			border-radius: 25px;
		}
		.mailbox-open-author-info {
			flex: 1;
			margin-left: 10px;
			.mailbox-open-author-info-email {
				font-weight: 500;
			}
			.mailbox-open-author-info-to {
				color: $color_39;
				font-size: 13px;
				.badge {
					vertical-align: middle;
				}
			}
		}
	}
	.mailbox-open-content-email {
		padding: 30px 0;
	}
}
.mailbox-open-content-email-attachments {
	margin: 30px 0 10px;
	display: block;
	padding: 20px 0 0;
	border-top: 1px solid #f1f1f1;
	.attachments-files-list {
		padding-left: 0;
		margin: 0;
		display: flex;
		-ms-flex-align: center;
		margin-bottom: 10px;
		&:not(:last-child) {
			margin-right: 20px;
		}
	}
	.attachments-files-list-item {
		padding: 10px 10px;
		border-radius: 10px;
		background: #f4f7fa;
		display: flex;
		align-items: center;
		margin-right: 10px;
		&:last-child {
			margin-bottom: 0;
		}
		.attachments-files-list-item-icon {
			display: block;
			width: 45px;
			height: 45px;
			text-align: center;
			margin-right: 10px;
			i {
				line-height: 45px;
				font-size: 18px;
				color: $color_30;
			}
		}
		.attachments-files-list-item-content {
			flex: 1;
			.attachments-files-list-item-title {
				display: block;
				font-size: 13px;
				font-weight: 500;
			}
			.attachments-files-list-item-size {
				font-size: 13px;
				opacity: .7;
			}
		}
		.attachments-files-list-item-download-btn {
			margin: 0 10px;
			i {
				font-size: 18px;
			}
		}
	}
}
.file-manager-folder {
	.file-manager-folder-icon {
		max-width: 70px;
		height: 50px;
		display: block;
		border-radius: 10px;
	}
	.file-manager-folder-title {
		margin: 15px 0 10px;
		display: block;
		color: $color_32;
		font-weight: 500;
	}
	.file-manager-folder-capacity {
		font-size: 24px;
		font-weight: 500;
	}
}
.file-manager-recent-item {
	.file-manager-recent-item-title {
		color: $color_12;
		text-decoration: none;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		&:hover {
			color: $color_30;
		}
	}
	.file-manager-recent-file-actions {
		margin-left: 10px;
		height: 21px;
		display: block;
		&::after {
			display: none;
		}
		i {
			font-size: 21px;
			line-height: 21px;
			color: $color_1;
		}
		&:hover {
			i {
				opacity: .7;
			}
		}
	}
}
.file-manager-group {
	.file-manager-group-info {
		margin-left: 15px;
	}
	.file-manager-group-title {
		color: $color_47;
		display: block;
		font-weight: 500;
		text-decoration: none;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		&:hover {
			color: $color_30;
		}
	}
	.file-manager-group-about {
		color: $color_60;
	}
}
.calendar-container {
	height: calc(100vh - 160px);
	margin-bottom: 0 !important;
	overflow: auto;
}
.fc {
	.fc-toolbar-title {
		font-weight: 500;
		color: $color_57;
	}
	.fc-col-header-cell-cushion {
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 500;
		margin: 7px 0;
		color: $color_39;
	}
	.fc-daygrid-day-number {
		text-decoration: none;
		font-size: 16px;
		margin: 5px;
		font-weight: 500;
		color: $color_60;
	}
	.fc-non-business {
		background-color: $background-color_8;
	}
	.fc-highlight {
		background-color: $background-color_47;
	}
	.fc-daygrid-event {
		color: $color_19;
		padding: 4px 7px;
		&:hover {
			background-color: $background-color_48;
		}
	}
	.fc-daygrid-more-link {
		color: $color_57;
		margin: 4px 10px;
	}
	.fc-popover-header {
		background-color: $background-color_8;
		padding: 10px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.fc-popover-title {
		font-size: 14px;
		color: $color_39;
	}
	.fc-popover {
		border: none;
		border-radius: 10px;
	}
}
.fc-theme-standard {
	.fc-scrollgrid {
		border-color: $border-color_26;
	}
	td {
		border-color: $border-color_26;
	}
	th {
		border-color: $border-color_26;
	}
}
.fc-h-event {
	background: #dff0fe;
	border-color: $border-color_24;
	padding: 4px 7px;
	.fc-event-main {
		color: $color_19;
	}
}
.fc-daygrid-event-dot {
	border-color: $border-color_8;
}
.todo-menu {
	margin: 25px 0 25px 25px;
	padding-right: 25px;
	height: calc(100% - 50px);
	overflow-y: auto;
	border-right: 1px solid #e3e6ea;
	.todo-menu-title {
		padding: 0 20px 10px 0;
		font-weight: 700;
		text-transform: uppercase;
		color: $color_17;
		font-size: 12px;
		opacity: .35;
		font-family: $font-family_2;
	}
	.todo-status-filter {
		li {
			a {
				color: $color_13;
				padding: 10px 20px;
				display: block;
				text-decoration: none;
				background: #f4f7fa;
				border-radius: 10px;
				margin-bottom: 12px;
				-webkit-transition: all .2s ease-in-out;
				-moz-transition: all .2s ease-in-out;
				-o-transition: all .2s ease-in-out;
				transition: all .2s ease-in-out;
				&:hover {
					background: #dff0fe;
					color: $color_19;
				}
				i {
					font-size: 18px;
					vertical-align: middle;
					margin-right: 10px;
				}
			}
			a.active {
				background: #dff0fe;
				color: $color_19;
			}
		}
	}
	.todo-label-filter {
		a {
			margin: 5px 0;
		}
	}
	.todo-preferences-filter {
		.todo-preferences-item {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
}
.todo-list {
	margin: 25px 25px 25px 0;
	height: calc(100% - 50px);
	overflow-y: auto;
	.todo-item {
		padding: 20px 0;
		display: flex;
		align-items: center;
		&:not(:last-child) {
			border-bottom: 1px solid #e3e6ea;
		}
		.todo-item-title {
			display: block;
			font-size: 16px;
			font-weight: 500;
			color: $color_57;
			.badge {
				margin-left: 10px;
				font-size: 10px;
				vertical-align: middle;
			}
		}
		.todo-item-subtitle {
			margin-top: 10px;
			display: block;
			opacity: .5;
		}
		.todo-item-content {
			flex: 1;
		}
		.todo-item-actions {
			opacity: 0;
			padding-left: 50px;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			a {
				margin: 0 5px;
				padding: 10px 7px;
				opacity: .6;
				-webkit-transition: all .2s ease-in-out;
				-moz-transition: all .2s ease-in-out;
				-o-transition: all .2s ease-in-out;
				transition: all .2s ease-in-out;
				&:hover {
					opacity: 1;
				}
			}
			.todo-item-delete {
				color: $color_8;
			}
			.todo-item-done {
				color: $color_7;
			}
		}
		&:hover {
			.todo-item-actions {
				opacity: 1;
			}
		}
	}
}
.invoice {
	margin-top: 30px;
}
.invoice-header {
	margin: -25px -30px 40px -30px;
	border-radius: 10px 10px 0 0;
	padding: 40px 30px;
	background: #2269f3;
	display: block;
	h3 {
		color: $color_23;
		font-weight: 600;
		margin: 0;
	}
	.invoice-issue-date {
		vertical-align: middle;
		float: right;
		color: $color_23;
		line-height: 29px;
	}
}
.invoice-description {
	margin-bottom: 40px;
	color: $color_61;
}
.invoice-table {
	img {
		width: 35px;
		height: 35px;
		border-radius: 16.5px;
		margin-left: 10px;
	}
	td {
		vertical-align: middle;
		&:not(:first-child) {
			&:not(:nth-child(2)) {
				text-align: right;
			}
		}
		&:nth-child(2) {
			max-width: 200px;
			white-space: nowrap;
			overflow: hidden !important;
			text-overflow: ellipsis;
		}
	}
	th {
		vertical-align: middle;
		&:not(:first-child) {
			&:not(:nth-child(2)) {
				text-align: right;
			}
		}
	}
}
.invoice-summary {
	padding: 20px 0;
}
.invoice-info {
	p {
		span {
			float: right;
		}
	}
	.invoice-info-actions {
		display: flex;
		margin-top: 20px;
		a {
			flex: 1;
		}
	}
}
.settings-integrations {
	.settings-integrations-item {
		display: flex;
		align-items: center;
		padding: 20px;
		&:not(:last-child) {
			margin-bottom: 10px;
			border-bottom: 1px solid #e3e6ea;
		}
		.settings-integrations-item-info {
			flex: 1;
			margin-right: 20px;
			display: block;
			img {
				height: 20px;
			}
			span {
				margin-top: 20px;
				color: $color_61;
				display: block;
			}
		}
	}
}
.settings-security-two-factor {
	padding: 25px;
	background-color: $background-color_8;
	border-radius: 10px;
	border: 1px solid #e3e6ea;
	h5 {
		color: $color_17;
	}
	span {
		margin-top: 20px;
		color: $color_61;
		display: block;
	}
}
.app-auth-container {
	height: 100vh;
	width: 650px;
	background: #fff;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 80px;
	.logo {
		a {
			display: flex;
			padding-left: 70px;
			height: 50px;
			background-size: 50px;
			align-items: center;
			text-decoration: none;
			color: $color_17;
			font-size: 21px;
			font-weight: 700;
		}
	}
	.auth-description {
		margin: 30px 0;
		color: $color_61;
	}
	.auth-submit {
		.auth-forgot-password {
			margin: 6px 0;
			display: inline-block;
		}
	}
	.auth-alts {
		a {
			width: 30px;
			height: 30px;
			margin-right: 15px;
			display: inline-block;
			content: '';
			opacity: .5;
			filter: grayscale(1);
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			&:hover {
				filter: none;
				opacity: 1;
			}
		}
		.auth-alts-google {
			background-size: cover;
		}
		.auth-alts-facebook {
			background-size: cover;
		}
		.auth-alts-twitter {
			background-size: cover;
		}
	}
	.auth-user {
		margin: 0 0 30px 0;
		padding: 15px;
		background: #f4f7fa;
		border-radius: 10px;
		display: flex;
		align-items: center;
		img {
			width: 50px;
			height: 50px;
			border-radius: 10px;
			margin-right: 20px;
		}
		.auth-user-fullname {
			font-weight: 500;
			flex: 1;
		}
		.auth-user-activity {
			color: $color_61;
		}
	}
}
.app-auth-lock-screen {
	.app-auth-background {
		flex: 1;
		height: 100%;
		content: '';
		display: block;
		background-size: 60%;
		background-position: center;
	}
}
.app-auth-sign-in {
	.app-auth-background {
		flex: 1;
		height: 100%;
		content: '';
		display: block;
		background-size: 60%;
		background-position: center;
	}
}
.app-auth-sign-up {
	.app-auth-background {
		flex: 1;
		height: 100%;
		content: '';
		display: block;
		background-size: 60%;
		background-position: center;
	}
}
.app-error {
	align-items: center;
	.app-error-info {
		min-width: 50%;
		padding-left: 200px;
		padding-right: 100px;
		h5 {
			font-size: 96px;
			font-weight: 800;
			color: $color_19;
		}
		span {
			margin: 50px 0;
			display: block;
			font-size: 16px;
		}
	}
	.app-error-background {
		height: 100%;
		flex: 1;
		content: '';
		display: block;
		background-size: 60%;
		background-position: center center;
	}
}
.search {
	z-index: 99999;
	width: calc(100% - 340px);
	display: flex;
	position: fixed;
	background: #fff;
	top: -70px;
	left: 310px;
	right: 30px;
	height: 70px;
	border-radius: 10px;
	padding: 13.5px 30px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	form {
		flex: 1;
	}
	.form-control {
		border: none !important;
		background: 0 0;
		padding-left: 0;
	}
	.toggle-search {
		float: right;
		margin: 10px 0 10px 10px;
		color: $color_62;
	}
}
.search-visible.header-large {
	.search {
		top: 0;
	}
}
.search-visible {
	.search {
		top: 30px;
		opacity: 1;
		visibility: visible;
	}
}
@media (min-width:1200px) {
	.menu-hover {
		.app-sidebar {
			overflow: visible;
			display: inline-block;
			position: relative;
			height: auto;
			&::before {
				position: fixed;
				top: 0;
				height: 100%;
				width: 280px;
				background: #fff;
				display: block;
				content: '';
				box-shadow: 0 0 1.25rem rgba(31,45,61,.04);
				z-index: -1;
			}
		}
		.app-container {
			display: inline-block;
			margin-left: 0;
		}
		.app-menu {
			overflow: visible !important;
			.accordion-menu {
				overflow-x: visible;
			}
			li {
				position: relative;
			}
			ul {
				>li {
					>a {
						-webkit-transition: padding-right 0s,color .2s ease-in-out,padding-left .2s ease-in-out,filter .2s ease-in-out;
						-moz-transition: padding-right 0s,color .2s ease-in-out,padding-left .2s ease-in-out,filter .2s ease-in-out;
						-o-transition: padding-right 0s,color .2s ease-in-out,padding-left .2s ease-in-out,filter .2s ease-in-out;
						transition: padding-right 0s,color .2s ease-in-out,padding-left .2s ease-in-out,filter .2s ease-in-out;
					}
					&:hover {
						width: calc(100% + 20px);
						>.sub-menu {
							height: auto;
							visibility: visible;
							opacity: 1;
							left: 200px;
						}
					}
					>ul {
						>li {
							&:hover {
								>a {
									margin-right: 20px;
									color: $color_22;
									font-weight: 500;
								}
							}
						}
					}
					>a+.sub-menu {
						display: block !important;
						position: absolute;
						margin-left: 20px;
						top: -15px;
						left: 180px;
						padding: 20px 0;
						background: #fff;
						box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
						-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
						-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
						min-width: 200px;
						opacity: 0;
						visibility: hidden;
						-webkit-transition: all .2s ease-in-out;
						-moz-transition: all .2s ease-in-out;
						-o-transition: all .2s ease-in-out;
						transition: all .2s ease-in-out;
					}
					>.sub-menu {
						&:after {
							position: absolute;
							top: 20px;
							left: -7px;
							right: auto;
							display: inline-block !important;
							border-right: 7px solid #fff;
							border-bottom: 7px solid transparent;
							border-top: 7px solid transparent;
							content: '';
						}
					}
				}
			}
			>ul {
				>li {
					&:hover {
						>a {
							padding-left: 20px;
							margin-right: 40px;
							color: $color_19;
						}
						>.sub-menu {
							left: 280px;
						}
					}
					>a+.sub-menu {
						top: -40px;
						left: 270px;
					}
					>a.active+.sub-menu {
						display: none;
					}
					>.sub-menu {
						&:after {
							top: 42.5px;
						}
					}
				}
			}
		}
	}
	.menu-dark.menu-hover {
		.app-sidebar {
			&::before {
				background: #13171f;
			}
		}
		.app-menu {
			>ul {
				>li {
					&:hover {
						>a {
							color: $color_25;
						}
					}
				}
			}
			ul {
				>li {
					ul {
						>li {
							&:hover {
								>a {
									color: $color_23;
								}
							}
						}
					}
				}
			}
		}
	}
	.menu-hover.sidebar-hidden {
		.app-sidebar {
			transform: none;
			margin-left: -280px;
		}
	}
	.app-menu {
		>ul {
			>li {
				&:hover {
					>a {
						>i {
							&:not(.has-sub-menu).material-icons-two-tone {
								filter: invert(34%) sepia(23%) saturate(3309%) hue-rotate(195deg) brightness(109%) contrast(115%);
							}
						}
					}
				}
			}
		}
	}
	.menu-hover.menu-dark {
		.app-menu {
			>ul {
				>li {
					ul {
						background: #1f2433;
					}
				}
			}
			ul {
				>li {
					>.sub-menu {
						&:after {
							border-right-color: $border-right-color_1;
						}
					}
				}
			}
		}
	}
	.placeholder-img-lg {
		font-size: 3.5rem;
	}
	.mailbox-container {
		height: calc(100vh - 160px);
		>.card {
			height: 100%;
		}
	}
	.todo-container {
		height: calc(100vh - 160px);
		margin-bottom: 0 !important;
		.row {
			height: 100%;
			>div {
				height: 100%;
			}
		}
	}
}
@media (max-width:1200px) {
	.app-content {
		.content-menu {
			position: absolute !important;
			background: #fff;
			z-index: 999;
			height: auto;
			border-radius: 10px;
			top: 180px;
			left: 30px;
			border-right: none !important;
			opacity: 0;
			visibility: hidden;
			height: auto !important;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
			-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
			-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
			li {
				a.active {
					&::after {
						left: 0 !important;
					}
				}
			}
		}
		.content-menu-toggle {
			display: inline-block;
		}
		.content-menu+.content-wrapper {
			width: 100% !important;
			float: none;
			.page-description {
				margin: 0 0 30px !important;
			}
		}
	}
	.content-menu-shown {
		.app-content {
			.content-menu {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.page-description.d-flex {
		display: block !important;
	}
	.page-description {
		.page-description-actions {
			a {
				margin: 20px 0 0;
			}
		}
	}
	.widget-stats-large {
		.widget-stats-large-info-container {
			margin: 0 -1px 0 0;
			border-radius: 0 0 10px 10px;
		}
	}
	.app-auth-container {
		width: 100%;
		padding-left: 200px;
		padding-right: 200px;
	}
	.app-error {
		.app-error-info {
			width: 100%;
			text-align: center;
			padding: 0;
		}
		.app-error-background {
			display: none;
		}
	}
	.search {
		width: calc(100% - 60px);
		left: 30px;
	}
}
@media (max-width:1199px) {
	.todo-list {
		margin: 25px;
		height: auto;
		overflow-y: auto;
	}
	.app-container {
		margin-left: 0;
		width: 100%;
	}
	.app-header {
		left: 330px;
		width: calc(100% - 360px);
	}
	.full-width-header {
		.app-header {
			left: 240px;
			width: calc(100% - 240px);
		}
	}
	.header-transaprent {
		.app-header {
			left: 280px;
			width: calc(100% - 280px);
			right: auto;
		}
		.app-sidebar {
			.app-menu {
				margin-top: -70px;
			}
		}
	}
	.app.sidebar-hidden.header-transaprent {
		.app-sidebar {
			transform: none;
			margin-left: -280px;
			position: fixed;
			top: 0;
			left: 0;
			margin: 0;
		}
	}
	.app {
		&:not(.sidebar-hidden) {
			.app-sidebar {
				transform: translateX(-280px);
			}
		}
	}
	.app.sidebar-hidden {
		.app-sidebar {
			transform: translateX(0);
		}
		.app-container {
			transform: translateX(280px);
		}
		overflow: hidden;
	}
	.app-sidebar {
		.logo {
			position: fixed;
			width: 280px;
			transform: translateX(310px);
			height: 70px;
			top: 30px;
			padding-top: 15px;
			padding-bottom: 15px;
			border-radius: 10px;
			background-color: $background-color_10;
			opacity: 1;
			box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
			-webkit-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
			-moz-box-shadow: 0 0 11px 1px rgba(0,0,0,.05);
		}
		.app-menu {
			height: 100% !important;
		}
	}
	.app.full-width-header {
		.app-sidebar {
			.logo {
				top: 0;
				left: 0;
				transform: translateX(280px);
				width: 240px;
				padding-left: 60px;
				border-radius: 0;
				background: #fff;
				color: $color_13;
				box-shadow: none;
			}
		}
	}
	.app.sidebar-colored {
		.app-sidebar {
			.logo {
				.sidebar-user-switcher {
					.user-info-text {
						color: $color_18;
					}
				}
				background-color: $background-color_10;
			}
			.logo-icon {
				.logo-text {
					color: $color_18;
				}
			}
		}
	}
	.hide-app-sidebar-mobile {
		visibility: hidden;
		opacity: 0;
		z-index: 1004;
		position: absolute;
		right: 0;
		background: rgba(0,0,0,.4);
		left: 0;
		height: 100%;
		content: '';
		display: block;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}
	.sidebar-hidden {
		.hide-app-sidebar-mobile {
			visibility: visible;
			opacity: 1;
			left: 280px;
		}
	}
	.header-large {
		.app-sidebar {
			.logo {
				top: 0;
				left: 0;
				transform: translateX(280px);
				border-radius: 0;
				width: 310px;
				box-shadow: none;
				border-right: 1px solid #e3e6ea;
				padding: 15px 40.5px;
			}
		}
		.app-header {
			width: calc(100% + 60px);
			margin-right: 0;
			left: 0;
			.navbar {
				margin-left: 310px;
			}
		}
	}
}
@media (max-width:991px) {
	.invoice-info {
		margin-bottom: 20px;
	}
}
@media (min-width:1199px) {
	.settings-security-two-factor {
		max-width: 75%;
	}
}
@media (max-width:780px) {
	.app-auth-container {
		padding-left: 50px;
		padding-right: 50px;
	}
}
@media (max-width:1600px) {
	.app-error {
		.app-error-info {
			padding-left: 100px;
		}
	}
}
@media (max-width:1100px) {
	.hidden-on-mobile {
		display: none !important;
	}
}
@media (max-width:576px) {
	.app-container {
		padding: 10px;
	}
	.app-sidebar {
		.logo {
			width: 100px;
			transform: translateX(290px);
			top: 10px;
			.sidebar-user-switcher {
				display: none;
			}
		}
	}
	.app.menu-off-canvas {
		.app-sidebar {
			.logo {
				width: 100px;
				transform: translateX(290px);
				top: 10px;
			}
		}
		.app-header {
			left: 120px;
			width: calc(100% - 130px);
		}
	}
	.app.sidebar-hidden {
		.app-sidebar {
			.logo.hidden-sidebar-logo {
				width: 100px;
				transform: translateX(290px);
				top: 10px;
			}
		}
	}
	.app.full-width-header {
		.app-sidebar {
			.logo {
				padding-left: 20px;
				width: 140px;
				padding-right: 20px;
			}
		}
	}
	.app.sidebar-hidden.full-width-header {
		.app-sidebar {
			.logo.hidden-sidebar-logo {
				padding-left: 20px;
				width: 140px;
				padding-right: 20px;
			}
		}
	}
	.header-large {
		.app-sidebar {
			.logo {
				width: 130px;
			}
		}
		.app-header {
			margin-top: -10px;
			.navbar {
				margin-left: 130px;
			}
		}
		.search {
			width: 100%;
			left: 0;
			right: 0;
		}
	}
	.app-header {
		left: 120px;
		width: calc(100% - 130px);
	}
	.header-transaprent {
		.app-header {
			left: 120px;
			width: calc(100% - 130px);
		}
	}
	.full-width-header {
		.app-header {
			left: 140px;
			width: calc(100% - 140px);
		}
	}
	.search {
		width: calc(100% - 20px);
		left: 10px;
		right: 10px;
	}
	.full-width-header.search-visible {
		.search {
			width: 100%;
			left: 0;
			right: 0;
			top: 0;
		}
	}
	.search-visible {
		.search {
			top: 10px;
		}
	}
	.app-content {
		margin-top: 110px;
	}
	.mailbox-open-content-email-attachments {
		.attachments-files-list {
			flex-direction: column;
		}
		.attachments-files-list-item {
			margin-bottom: 10px;
		}
	}
}
