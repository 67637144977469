@import './_variables';
@import './_mixins';

* {
	box-sizing: border-box;
	outline: none;
}
html,
body {
	height: 100%;
	overflow-x: hidden;
}
body {
	position: relative;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $main-text-color;
	background-color: $main-background-color;
	font-size: $main-text-size;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1.title {
	margin-top: 30px;
	margin-bottom: 60px;
}

.form {
	.row-2-col {
		display: block;

		.item {
			margin: auto;
			width: 300px;
		}
	}
}

.text-left-important {
	text-align: left !important;
}

.text-right-important {
	text-align: right !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
	padding: 0px;
}

.MuiDataGrid-columnHeader, .MuiDataGrid-cell {
	padding: 0px 30px !important;
}

.width-fit-content {
	width: fit-content !important;
}

.menu-list-order {
	display: block;
	width: 100%;
	text-align: center;
	margin: auto;
}

.menu-list-order-draggables-container {
    overflow-y: scroll;
    height: 80vh;
    position: relative;
	padding-right: 20px;
}

.menu-item-draggable-container {
	left: auto !important;

	div {
		min-width: 275px;
		margin-bottom: 20px;

	}
}

.menu-item-draggable{
	vertical-align: middle;
	margin-bottom: 0px;
	font-size: 16px;
	span {
		margin-right: 15px;
	}
}

.dish-from-menu-list-order{
	margin: auto;
	margin-bottom: 30px;
	width: 300px;
	right: 0 !important;
	left: 0 !important;
}

//Table mui
.MuiDataGrid-footerContainer {
	background-color: var(--bs-table-bg) !important;
}

.d-flex {
	gap: 20px;
}

.link-normal {
	text-decoration: none;
	color: white;
	
}

.cursor-pointer {
	cursor: pointer;
}

.hide {
	display: none !important;
}

#iphone-nav {
	.container-fluid {
		background-color: #fff !important;
	}

	.navbar-collapse {
		width: 150px;
	}

	padding: 0px 10px 0px 45px;
}

@media screen and (max-width: 768px) {
	.form {
		.row-2-col {
			.item {
				margin-bottom: 30px !important;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	#iphone-nav {
		.navbar-collapse.collapse.show {
			box-shadow: 10px 5px 5px #999999;
		}

		.navbar-toggler{
			margin-top: 5px;
		}
	}
}